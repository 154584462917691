import { CSSProperties } from "react";

export const override: CSSProperties = {
    display:      "block",
    margin:       "0 auto",
    borderColor:  "blue",
    position:     "absolute",
    top:          "36%",
    left:         "42%",
    zIndex:       "100"
};