import { Tooltip } from "antd";
import { copyToClipboard } from "../../../shared";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { removeBinaryFile } from ".";


export const renderInstall = (record: any) => record.install.length > 0 ?  // eslint-disable-next-line
    <Tooltip placement="topLeft"
        title={record.install}>
        <button className="link_btn" onClick={() => { copyToClipboard(record.num + 'id', record.install, 'cpd'); }} >Install</button>
    </Tooltip> : <></>

export const renderResult = (record: any) =>
    <span id={record.num + "id"} onDoubleClick={() => { copyToClipboard(record.num + 'id', record.result, 'cpd'); }}>
        {record.result.length > 0 ? record.result.split('/').pop() :
            <LoadingOutlined className='reload-btn' />}
    </span>;

export const render_actions = (record: any) =>
    <Tooltip title="Delete" color="red">
        <DeleteOutlined onClick={() => { removeBinaryFile(record.id) }}
            title="Delete" style={{ color: "red", fontSize: "19px" }} />
    </Tooltip>



