import React from 'react'
import { TargetNote, renderHostLinks } from '../../../shared'
import { List, Tooltip } from 'antd'
import cls from '../notes.module.css';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';

type Props = {
    id: string | undefined,
    notes: TargetNote[],
    deleteNote: (item: TargetNote) => void
}

const contentTxt = (i: TargetNote ) => `
    Last updated by: ${i.user}
    Version: ${i.version}
    Created: ${moment(i.created_at).format('MMM DD YYYY, HH:mm')}
    Updated: ${moment(i.updated_at).format('MMM DD YYYY, HH:mm')}
    Hosts: ${i.hosts.length > 0 ? 
        i.hosts.map(host => host.hostname).join(', ') : 'There are no hosts'}
    Content: ${i.content}
`;

const NodesList: React.FC<Props> = ({ id, notes, deleteNote }) => {
    return (
        <List className={cls.nodesList} itemLayout="horizontal" dataSource={notes}
            renderItem={(i, index) => (
                <List.Item>
                    <List.Item.Meta title={<>
                        <a href={`/ui/target/${id}/note/${i.id}`} 
                            className={cls.titleLink}> {i.title + ' >'} </a> 
                        <Tooltip title="Delete note" color='red'>
                            <DeleteOutlined style={{ marginTop: "3px" }} 
                                onClick={() => { deleteNote(i) }} 
                                className={cls.deleteBtn} />
                        </Tooltip>
                        <a href={ 'data:text/plain;charset=UTF-8,' +  contentTxt(i) } 
                            download={ i.title + '.txt' }>
                            <Tooltip title="Download as .txt" color='darkblue'>
                                <DownloadOutlined style={{ marginTop: "3px", 
                                    color: '#1677ff', float: 'right', fontSize: '19px'
                                 }} />
                            </Tooltip>
                        </a>
                        </>} description={<>
                            <><b>Last updated by:</b> {i.user}</>/
                            <><b> Version:</b> {i.version} </>/
                            <><b> Created:</b> 
                            { moment(i.created_at).format('MMM DD YYYY, HH:mm') } </>/
                            <><b> Updated:</b> 
                            { moment(i.updated_at).format('MMM DD YYYY, HH:mm') } </>
                            <br />
                            <><b>Hosts:</b> 
                            { i.hosts.length > 0 ? 
                                renderHostLinks(i.hosts) : 'There are no hosts' } </> 
                        </>} 
                    />
                </List.Item>
            )}
        />
    )
}

export default NodesList