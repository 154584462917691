import { Targets } from "./shared";

export default class AuthLocalStorage {
  static THEME_KEY: string = "theme";
  static USER_STORAGE_KEY: string = "Client_Id";
  static ROLE_KEY: string = "role";
  static SELECTS_KEY: string = "selects";
  static TARGET_NET_KEY: string = "target_net";
  static BEACON_KEY: string = "beacon";
  static TARGET_BEACON_KEY: string = "target_beacon";
  static CREDS_KEY: string = "creds";
  static HOST_KEY: string = "host";
  static COLLECTIONS_KEY: string = "collections";

  static getUserID() {
    return window.sessionStorage.getItem(AuthLocalStorage.USER_STORAGE_KEY);
  }

  static setUserID(userId: string) {
    window.sessionStorage.setItem(AuthLocalStorage.USER_STORAGE_KEY, userId);
  }

  static removeUserID(): void {
    window.sessionStorage.removeItem(AuthLocalStorage.USER_STORAGE_KEY);
  }

  static getRole() {
    return window.sessionStorage.getItem(AuthLocalStorage.ROLE_KEY);
  }

  static setRole(role: string) {
    window.sessionStorage.setItem(AuthLocalStorage.ROLE_KEY, role);
  }

  static removeRole(): void {
    window.sessionStorage.removeItem(AuthLocalStorage.ROLE_KEY);
  }

  static setCollectionsSelects(selects: Targets): void {
    window.sessionStorage.setItem(
      AuthLocalStorage.COLLECTIONS_KEY,
      JSON.stringify(selects)
    );
  }

  static getCollectionsSelects() {
    return window.sessionStorage.getItem(AuthLocalStorage.COLLECTIONS_KEY);
  }

  static removeCollectionsSelects(): void {
    window.sessionStorage.removeItem(AuthLocalStorage.COLLECTIONS_KEY);
  }

  static setTargetSelects(selects: Targets): void {
    window.sessionStorage.setItem(
      AuthLocalStorage.SELECTS_KEY,
      JSON.stringify(selects)
    );
  }

  static getTargetSelects() {
    return window.sessionStorage.getItem(AuthLocalStorage.SELECTS_KEY);
  }

  static removeTargetSelects(): void {
    window.sessionStorage.removeItem(AuthLocalStorage.SELECTS_KEY);
  }

  static setTargetNetSelects(selects: Targets): void {
    window.sessionStorage.setItem(
      AuthLocalStorage.TARGET_NET_KEY,
      JSON.stringify(selects)
    );
  }

  static getTargetNetSelects() {
    return window.sessionStorage.getItem(AuthLocalStorage.TARGET_NET_KEY);
  }

  static removeTargetNetSelects(): void {
    window.sessionStorage.removeItem(AuthLocalStorage.TARGET_NET_KEY);
  }

  static setBeaconSelects(beacon: Targets): void {
    window.sessionStorage.setItem(
      AuthLocalStorage.BEACON_KEY,
      JSON.stringify(beacon)
    );
  }

  static getBeaconSelects() {
    return window.sessionStorage.getItem(AuthLocalStorage.BEACON_KEY);
  }

  static removeBeaconSelects(): void {
    window.sessionStorage.removeItem(AuthLocalStorage.BEACON_KEY);
  }

  static setTargetBeaconSelects(beacon: Targets): void {
    window.sessionStorage.setItem(
      AuthLocalStorage.BEACON_KEY,
      JSON.stringify(beacon)
    );
  }

  static getTargetBeaconSelects() {
    return window.sessionStorage.getItem(AuthLocalStorage.BEACON_KEY);
  }

  static removeTargetBeaconSelects(): void {
    window.sessionStorage.removeItem(AuthLocalStorage.BEACON_KEY);
  }

  static setHostSelects(beacon: Targets): void {
    window.sessionStorage.setItem(
      AuthLocalStorage.HOST_KEY,
      JSON.stringify(beacon)
    );
  }

  static getHostSelects() {
    return window.sessionStorage.getItem(AuthLocalStorage.HOST_KEY);
  }

  static removeHostSelects(): void {
    window.sessionStorage.removeItem(AuthLocalStorage.HOST_KEY);
  }

  static setCredsSelects(creds: Targets): void {
    window.sessionStorage.setItem(
      AuthLocalStorage.CREDS_KEY,
      JSON.stringify(creds)
    );
  }

  static getCredsSelects() {
    return window.sessionStorage.getItem(AuthLocalStorage.CREDS_KEY);
  }

  static removeCredsSelects(): void {
    window.sessionStorage.removeItem(AuthLocalStorage.CREDS_KEY);
  }

  static setTheme(theme: string) {
    window.localStorage.setItem(AuthLocalStorage.THEME_KEY, theme);
  }

  static getTheme() {
    return window.localStorage.getItem(AuthLocalStorage.THEME_KEY);
  }

  static removeTheme(): void {
    window.localStorage.removeItem(AuthLocalStorage.THEME_KEY);
  }
}
