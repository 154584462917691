export class Targets {
    target_id?: string | undefined;
    type: string;
    search_phrase: string;
    name: string;
    constructor() {
        this.target_id = undefined;
        this.type = 'all';
        this.search_phrase = '';
        this.name = 'all';
    }
}

export class HostSelect {
    target_id?: string | undefined;
    search_phrase: string;
    constructor() {
        this.target_id = undefined;
        this.search_phrase = '';
    }
}