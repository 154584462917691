import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { getOptions, sortOptions } from "../../../../shared";
import { Tooltip } from "antd";
import { BinaryStore } from "../../Store";
import { showHelpModal } from "../../actions/form_actions";
import { adminStore } from "../../../AdminPage/Store";

const bbParts = ["all"];

const os_options = ["darwin", "freebsd", "linux", "windows", "win7"];
const bild_kind = ["all", "collector", "bb", "bb-shell", "beacon"];
const arch_options = ["amd64", "arm64", "386"];
const logging_types = ["none", "file", "console"];

export const target_os_options = getOptions(os_options);
export const target_arch_options = getOptions(arch_options);
export const client_kind_options = getOptions(bild_kind);
export const logging_options = getOptions(logging_types);

export const kind_options = (): (BaseOptionType | DefaultOptionType)[] => {
  const handleHelp = (event: any, item: string) => {
    event.stopPropagation();
    showHelpModal(item);
  };
  const state = BinaryStore.storeState.getState();

  const userState = adminStore.storeState.getState();

  return sortOptions(userState.current_user?.allowed_binaries)?.map((i) => {
    return {
      value: i,
      label:
        (state.checkedKind && state.checkedKind?.indexOf(i) > -1) ||
        i === "beacon-dll" ||
        i === "revshell-dll" ? (
          i
        ) : (
          <>
            {i}
            <Tooltip title="Info" color="green">
              <QuestionCircleOutlined
                title="Info"
                onClick={(e) => handleHelp(e, i)}
                style={{ float: "right", color: "green", fontSize: "19px" }}
              />
            </Tooltip>{" "}
          </>
        ),
    };
  });
};

export const parts_options = () => {
  const handleHelp = (event: any, item: string) => {
    event.stopPropagation();
    showHelpModal(item);
  };
  const state = BinaryStore.storeState.getState();

  return sortOptions(
    bbParts.concat(state.bbOptions).map((i) => {
      return {
        value: i,
        label:
          i === "all" ||
          (state.checkedValues && state.checkedValues?.indexOf(i) > -1) ? (
            i
          ) : (
            <>
              {i}{" "}
              <Tooltip title="Info" color="green">
                <QuestionCircleOutlined
                  title="Info"
                  onClick={(e) => handleHelp(e, `bb+${i}`)}
                  style={{ float: "right", color: "green", fontSize: "19px" }}
                />
              </Tooltip>{" "}
            </>
          ),
      };
    })
  );
};
