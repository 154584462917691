import { ColumnsType } from "antd/es/table";
import {
  DateCorrector,
  getFilterOptions,
  onlyUnique,
  sortOptions,
} from "../../../shared";
import { col_actions } from "../actions/actions";
import { CMDStore } from "../store";
import moment from "moment";
import { renderBeaconsIcons } from "../../shared";

const state = CMDStore.storeState.getState();

export const get_cmdinfo_columns = () =>
  (
    [
      {
        title: "Command",
        dataIndex: "cmd",
        key: "cmd",
        filters: sortOptions(
          getFilterOptions(
            state.hostCommands?.map((x) => x.cmd)?.filter(onlyUnique)
          )
        ),
        filterSearch: true,
        onFilter: (value: any, record: any) => {
          return record.cmd?.includes(value);
        },
        ellipsis: {
          showTitle: true,
        },
      },
      !state.ids.host_id
        ? {
            title: "Hostname",
            dataIndex: "hostname",
            key: "hostname",
            filters: sortOptions(
              getFilterOptions(
                state.hostCommands?.map((x) => x.hostname)?.filter(onlyUnique)
              )
            ),
            filterSearch: true,
            onFilter: (value: any, record: any) => {
              return record.hostname?.includes(value);
            },
            render: (value: any, record: any) => {
              return value && record.target_host_id ? (
                <a href={`/ui/target-host/${record.target_host_id}/info`}>
                  {value}
                </a>
              ) : (
                <>{value}</>
              );
            },
          }
        : null,
      !state.ids.target_id
        ? {
            title: "Target",
            dataIndex: "target",
            key: "target",
            filters: sortOptions(
              getFilterOptions(
                state.hostCommands?.map((x) => x.target)?.filter(onlyUnique)
              )
            ),
            filterSearch: true,
            onFilter: (value: any, record: any) => {
              return record.target?.includes(value);
            },
            render: (value: any, record: any) => {
              return value && record.target_id ? (
                <a href={`/ui/target/${record.target_id}/info`}>{value}</a>
              ) : (
                <>{value}</>
              );
            },
          }
        : null,
      {
        title: "Method",
        dataIndex: "method",
        key: "method",
        width: "120px",
        render: (value: string) =>
          value && value.length > 0 && renderBeaconsIcons([value]),
      },

      {
        title: "Created",
        dataIndex: "created_at",
        key: "created_at",
        render: (value: any, record: any) => (
          <DateCorrector date={new Date(record?.created_at)} />
        ),
      },
      {
        title: "Processed",
        dataIndex: "processed_at",
        key: "processed_at",
        render: (value: any, record: any) =>
          record?.processed_at ? (
            <DateCorrector date={new Date(record?.processed_at)} />
          ) : (
            <></>
          ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: 100,
        render: (value: any, record: any) => col_actions(record, true),
      },
    ] as ColumnsType<any>
  ).filter((i) => i !== null);

export const get_cmd_columns = () => [
  {
    title: "cmd",
    dataIndex: "cmd",
    key: "cmd",
    width: "55%",
    filters: sortOptions(
      getFilterOptions(state.commands?.map((x) => x.cmd)?.filter(onlyUnique))
    ),
    filterSearch: true,
    onFilter: (value: any, record: any) => {
      return record.cmd?.includes(value);
    },
  },
  {
    title: "user",
    dataIndex: "user",
    key: "user",
  },
  {
    title: "Default method",
    dataIndex: "method",
    key: "method",
    render: (value: string) =>
      value && value.length > 0 && renderBeaconsIcons([value]),
  },
  {
    title: "Created",
    dataIndex: "created_at",
    key: "created_at",
    render: (value: any, record: any) => (
      <DateCorrector date={new Date(record?.created_at)} />
    ),
    sorter: (a: { created_at: any }, b: { created_at: any }) =>
      new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
  },
  {
    title: "Scheduled for",
    dataIndex: "scheduled_for",
    key: "scheduled_for",
    render: (value: any, record: any) =>
      record?.scheduled_for ? (
        <>{moment(record?.scheduled_for).format("MMM DD YYYY, HH:mm")}</>
      ) : (
        <></>
      ),
    sorter: (a: { scheduled_for: any }, b: { scheduled_for: any }) =>
      new Date(a.scheduled_for).getTime() - new Date(b.scheduled_for).getTime(),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    width: 100,
    render: (value: any, record: any) => col_actions(record),
  },
];
