import dayjs from "dayjs";
import cls from "../stat.module.css";
import { Button, DatePicker, Form, FormInstance } from "antd";
import { EnterOutlined } from "@ant-design/icons";

type Props = {
  form: FormInstance<any>;
  loadData: () => void;
  type?: string;
};

const DateForm = ({ form, loadData, type }: Props) => {
  const init_val = {
    since: type ? dayjs().subtract(7, "days") : dayjs().subtract(12, "hours"),
    until: dayjs(),
  };

  return (
    <Form
      initialValues={init_val}
      className={type ? cls.collectionDateForm : cls.dateForm}
      form={form}
    >
      <Form.Item
        className={"date_form_item"}
        name="since"
        label="Since"
        style={{ marginLeft: "20px" }}
        rules={[{ required: true }]}
      >
        <DatePicker
          style={{ width: "100%" }}
          showTime
          maxDate={dayjs()}
          className={cls.picker}
        />
      </Form.Item>
      <Form.Item
        name="until"
        label="Until"
        style={{
          marginLeft: "20px",
          marginRight: type ? "" : "20px",
        }}
        className={"date_form_item"}
        rules={[{ required: true }]}
      >
        <DatePicker
          style={{ width: "100%" }}
          showTime
          maxDate={dayjs()}
          className={cls.picker}
        />
      </Form.Item>
      {!type ? (
        <Button className={cls.btn_sub} onClick={loadData}>
          Submit
        </Button>
      ) : (
        <>
          <EnterOutlined className={cls.enterbtn} onClick={loadData} />
        </>
      )}
    </Form>
  );
};

export default DateForm;
