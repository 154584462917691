import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  DeleteOutlined,
  EditOutlined,
  RightSquareOutlined,
} from "@ant-design/icons";
import { FloatButton, Modal, Table, Tooltip } from "antd";

import { ClientsApi } from "../../Api";
import { getCookie } from "../../shared";
import ManageClientModal from "./Modals/ManageClientModal";
import "./clientStyles.css";
import { get_client_columns } from "./columns/clientColumns";
import { useAdminState } from "./Store";

const CLIENT_SERVISE = new ClientsApi();

const ClientsPage: React.FC = () => {
  const token = getCookie("token") as string;
  const navigate = useNavigate();
  const [state, actions] = useAdminState();

  const getClientsList = async () => {
    actions.setClients(await CLIENT_SERVISE.getData());
  };

  const deleteClient = async (record: any) => {
    Modal.confirm({
      title: (
        <span>
          The specified client has users. Do you really want to delete the
          <b style={{ color: "red" }}>{record.name.toUpperCase()}</b>-client
          with all users?
        </span>
      ),
      okText: "Delete",
      okType: "danger",
      onOk: async () => {
        await CLIENT_SERVISE.removeClientById(record.id);
        getClientsList();
      },
    });
  };

  const updateClient = async (client: any) => {
    await CLIENT_SERVISE.updateClient(client);
    getClientsList();
    actions.setEditedClient(null);
  };

  const setNewClient = async (new_client: any) => {
    await CLIENT_SERVISE.addNewClient(new_client);
    actions.setEditedClient(null);
    getClientsList();
  };

  const render_actions = (record: any) => {
    return (
      <>
        <EditOutlined
          title="Edit"
          onClick={() => {
            actions.setEditedClient(record);
            actions.setIsClientEdit(true);
          }}
          style={{ color: "green", marginLeft: "20px" }}
        />
        <DeleteOutlined
          title="Delete"
          onClick={() => {
            deleteClient(record);
          }}
          style={{ color: "red", marginLeft: "22px", padding: "5px" }}
        />
        <Tooltip title="Users" color="rgb(74, 74, 245)">
          <RightSquareOutlined
            title="Users"
            style={{ color: "rgb(74, 74, 245)", marginLeft: "22px" }}
            onClick={() => {
              navigate(`/ui/users/${record.id}`);
            }}
          />
        </Tooltip>
      </>
    );
  };

  useEffect(() => {
    getClientsList();
    document.title = "Clients / BartUI";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !token ? (
    <></>
  ) : (
    <div className="messages_table">
      <Table
        className="clientsTable"
        columns={get_client_columns(render_actions, actions.setIsClientEdit)}
        dataSource={state.clients}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "40", "60"],
        }}
        rowKey="id"
      />
      <ManageClientModal
        updateClient={updateClient}
        setNewClient={setNewClient}
      />
      <FloatButton.BackTop />
    </div>
  );
};
export default ClientsPage;
