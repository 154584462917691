import React from 'react';
import { Connection_paths } from '../../../../../shared';
import { Table } from 'antd';
import { connections_cols } from './columns';

type Props = {
  connections: Connection_paths[]
}

export const Connections: React.FC<Props> = ({ connections }) => {
  return (
    <div>
      <Table
        className='credsTable'
        columns={connections_cols}
        dataSource={connections}
        pagination={false}
        rowKey='id' />
    </div>
  )
}
