import { ColumnsType, SorterResult } from "antd/es/table/interface";
import {
  Beacon,
  Filters,
  Targets,
  actionsType,
  getCache,
  setCache,
} from "../../../shared";
import { IBeaconState } from "./IBeaconState";
import AuthLocalStorage from "../../../AuthLocalStorage";
import { BeaconApi } from "../../../Api";

const BEACON_CLIENT = new BeaconApi();

export const actions = {
  setActive:
    (isActive: boolean) =>
    ({ setState, getState }: actionsType<IBeaconState>) =>
      setState({ ...getState(), isActive }),

  setLoading:
    (isLoading: boolean) =>
    ({ setState, getState }: actionsType<IBeaconState>) =>
      setState({ ...getState(), isLoading }),

  setMulti:
    (isMulti: boolean) =>
    ({ setState, getState }: actionsType<IBeaconState>) =>
      setState({ ...getState(), isMulti }),

  setTableData:
    (beacon?: Beacon[], id?: string) =>
    async ({ setState, getState }: actionsType<IBeaconState>) => {
      let target =
        !id && AuthLocalStorage.getBeaconSelects()
          ? JSON.parse(AuthLocalStorage.getBeaconSelects()!)
          : id && AuthLocalStorage.getTargetBeaconSelects()
          ? JSON.parse(AuthLocalStorage.getTargetBeaconSelects()!)
          : new Targets();
      target.type = target.type === "all" ? "" : target.type;
      if (id) {
        target.target_id = id;
      }

      let tabledata = !!beacon?.length
        ? beacon
        : await BEACON_CLIENT.getListByParams(target);
      actions.setLoading(false);
      setState({ ...getState(), tabledata });
    },

  deleteTableRecord:
    (recordId: string) =>
    ({ setState, getState }: actionsType<IBeaconState>) => {
      const tabledata = [
        ...getState().tabledata.filter((record) => record.id !== recordId),
      ];
      setState({ ...getState(), tabledata });
    },

  setFilteredData:
    (filteredData: Beacon[]) =>
    ({ setState, getState }: actionsType<IBeaconState>) =>
      setState({ ...getState(), filteredData }),

  setFilteredInfo:
    (filteredInfo: Filters) =>
    ({ setState, getState }: actionsType<IBeaconState>) => {
      setState({ ...getState(), filteredInfo });
    },

  setTypesList:
    () =>
    async ({ setState, getState }: actionsType<IBeaconState>) => {
      let types = await getCache("beaconTypes");
      let typesList: string[] = [];
      typesList =
        types?.length === 0 ? await BEACON_CLIENT.getBeaconTypes() : types;
      types?.length === 0 && setCache(typesList, "beaconTypes");
      setState({ ...getState(), typesList });
    },

  setSelectedColumns:
    (selectedColumns: ColumnsType<Beacon>) =>
    ({ setState, getState }: actionsType<IBeaconState>) =>
      setState({ ...getState(), selectedColumns }),

  changeSelectedColumnsSorting:
    (sorter: SorterResult<Beacon>) =>
    ({ setState, getState }: actionsType<IBeaconState>) => {
      const selectedColumns = [
        ...getState().selectedColumns.map((col) => {
          if (col.key === sorter.columnKey) {
            col.sortOrder = sorter.order;
          } else {
            col.sortOrder = undefined;
          }
          return col;
        }),
      ];
      setState({ ...getState(), selectedColumns });
    },

  setSelectedRowKeys:
    (selectedRowKeys: never[]) =>
    ({ setState, getState }: actionsType<IBeaconState>) =>
      setState({ ...getState(), selectedRowKeys }),

  setCheckSwith:
    (checkSwith: boolean) =>
    ({ setState, getState }: actionsType<IBeaconState>) =>
      setState({ ...getState(), checkSwith }),
};
