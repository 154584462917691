import { createHook, createStore, defaultRegistry } from "react-sweet-state";
import { INetcatState } from "./INetcatState";
import { actions } from "./Actions";

const initialState: INetcatState = {
  isActive: false,
  isLoading: false,
  tabledata: [],
  filteredData: [],
  filteredInfo: undefined,
  typesList: [],
  selectedColumns: [],
  checkSwith: false,
};

export const Store = createStore<INetcatState, typeof actions>({
  initialState,
  actions,
});

export const useNetcatState = createHook(Store);

export const NetcatStore = defaultRegistry.getStore(Store);
