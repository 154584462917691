import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  PauseCircleFilled,
  PauseCircleOutlined,
  PlayCircleFilled,
  PlayCircleOutlined,
  QuestionCircleFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Modal, Popover, Tooltip } from "antd";
import { UserTokensApi } from "../../../Api";

const TOKEN_API = new UserTokensApi();

export type Status =
  | "connected"
  | "disconnected"
  | "refused"
  | "listening"
  | "online"
  | unknown;

export const StatusIcon = (Status: Status): JSX.Element => {
  const statusIcon: JSX.Element = (() => {
    switch (Status) {
      case "connected":
        return <CheckCircleFilled style={{ color: "green" }} />;
      case "disconnected":
        return <ExclamationCircleFilled style={{ color: "red" }} />;
      case "refused":
        return <PauseCircleFilled style={{ color: "blue" }} />;
      case "listening":
        return <ClockCircleFilled style={{ color: "#5de05d" }} />;
      case "online":
        return <PlayCircleFilled style={{ color: "rgb(93, 224, 200)" }} />;
      default:
        return <QuestionCircleFilled style={{ color: "grey" }} />;
    }
  })();
  statusIcon.props.style.fontSize = "min(1.2vw, 19px)";

  return (
    <Tooltip
      title={typeof Status === "string" ? Status : "other"}
      color={statusIcon.props.style.color}
    >
      {statusIcon}
    </Tooltip>
  );
};

export type BeaconStatus =
  | "active"
  | "paused"
  | "expired"
  | "deleted"
  | unknown;

export const BeaconStatusIcon = (
  Status: BeaconStatus,
  token: string,
  rerender?: (isRerender: boolean) => void
): JSX.Element => {
  const statusIcon: JSX.Element = (() => {
    switch (Status) {
      case "active":
        return (
          <PlayCircleOutlined twoToneColor="green" style={{ color: "green" }} />
        );
      case "paused":
        return <PauseCircleOutlined style={{ color: "yellow" }} />;
      case "expired":
        return <CloseCircleOutlined style={{ color: "red" }} />;
      case "deleted":
        return <CloseCircleOutlined style={{ color: "red" }} />;
      default:
        return <QuestionCircleOutlined style={{ color: "grey" }} />;
    }
  })();
  statusIcon.props.style.fontSize = "min(1.2vw, 19px)";

  return (
    <Tooltip
      title={typeof Status === "string" ? Status : "Unknown status"}
      color={statusIcon.props.style.color}
    >
      {token ? (
        <Popover
          content={popupContent(Status, token, rerender)}
          trigger="click"
        >
          {statusIcon}
        </Popover>
      ) : (
        <> {statusIcon}</>
      )}
    </Tooltip>
  );
};

const popupContent = (
  Status: BeaconStatus,
  token: string,
  rerender?: (isRerender: boolean) => void
) => {
  return (
    <div>
      <a href={`/ui/binary/${token}/info`}>{"Usages"}</a>
      <>
        <Tooltip
          title={Status === "active" ? "Deactivate" : "Activate"}
          color={Status === "active" ? "purple" : "green"}
        >
          {Status === "active" ? (
            <PauseCircleOutlined
              onClick={async () => {
                await TOKEN_API.switchToken(token);
                rerender && rerender(true);
              }}
              style={{
                color: "purple",
                marginLeft: "22px",
                cursor: "pointer",
                fontSize: "19px",
              }}
            />
          ) : (
            <PlayCircleOutlined
              onClick={async () => {
                await TOKEN_API.switchToken(token);
                rerender && rerender(true);
              }}
              style={{
                color: "green",
                marginLeft: "22px",
                cursor: "pointer",
                fontSize: "19px",
              }}
            />
          )}
        </Tooltip>
        <DeleteOutlined
          title="Delete"
          onClick={async () => deleteToken(token, rerender)}
          style={{ color: "red", marginLeft: "22px", padding: "5px" }}
        />
      </>
    </div>
  );
};

const deleteToken = (id: string, rerender?: (isRerender: boolean) => void) => {
  Modal.confirm({
    title: <span>Are you sure you want to permanently remove the token?</span>,
    okText: "Delete",
    okType: "danger",
    onOk: async () => {
      await TOKEN_API.deleteTokenByID(id);
      rerender && rerender(true);
    },
  });
};
