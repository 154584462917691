import { Modal, Select } from "antd";
import { Host, SearchTargetsModel } from "../../../shared";
import {
  CommentOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons";
import { HostStore } from "../Store";
import { TargetApi } from "../../../Api";
import TextArea from "antd/es/input/TextArea";
import { getTargetSelects } from "../../shared";

const TARGET_CLIENT = new TargetApi();

export const render_actions = (record: any) => {
  return (
    <>
      <InfoCircleOutlined
        title="Info"
        style={{
          color: "rgb(74, 74, 245)",
          fontSize: "19px",
          marginLeft: "16px",
          cursor: "pointer",
        }}
        onClick={() => {
          window.location.href = `/ui/target-host/${record.id}/info`;
        }}
      />
      <CommentOutlined
        title="Comment"
        style={{
          color: "#b980e1",
          marginLeft: "16px",
          fontSize: "19px",
          cursor: "pointer",
        }}
        onClick={() => {
          addComment(record);
        }}
      />
      <DeleteOutlined
        onClick={() => {
          removeConnection(record);
        }}
        title="Delete"
        style={{ color: "red", marginLeft: "22px", fontSize: "19px" }}
      />
    </>
  );
};

const removeConnection = async (record: Host) => {
  HostStore.actions.setActive(false);
  Modal.confirm({
    icon: <UsergroupDeleteOutlined />,
    title: (
      <span>
        Are you sure you want to delete the{" "}
        <b style={{ color: "red" }}>{record.hostname.toUpperCase()}</b> target
        host? All related entities will be deleted
      </span>
    ),
    okText: "delete",
    okType: "danger",
    onOk: async () => {
      await TARGET_CLIENT.removeById(record.id);
      HostStore.actions.deleteTableRecord(record.id);
      HostStore.actions.setActive(true);
      HostStore.actions.setTableData();
    },
    onCancel: () => HostStore.actions.setActive(true),
  });
};

const addComment = async (record: Host) => {
  Modal.confirm({
    icon: <CommentOutlined />,
    className: "add_server_modal",
    title: "Comment",
    content: (
      <TextArea
        rows={8}
        className="coment_area"
        id="comentArea"
        defaultValue={record.comment}
      />
    ),
    width: 550,
    okText: "Save",
    onCancel: () => {},
    onOk: () => _sendComment(record),
  });
};

const _sendComment = async (record: any) => {
  let newValue = (document.getElementById("comentArea") as HTMLTextAreaElement)
    ?.value;
  await TARGET_CLIENT.updateHost({
    id: record.id,
    commentText: newValue,
    target_id: record.target_id,
  });
  HostStore.actions.setTableData();
};

export const render_targets = (
  record: any,
  targetsList: SearchTargetsModel[]
) => {
  return (
    <Select
      showSearch={true}
      value={record.target ? record.target : "none"}
      options={getTargetSelects(targetsList)}
      style={{ width: "100%", display: "flex" }}
      onChange={async (value) => {
        await TARGET_CLIENT.updateHost({
          id: record.id,
          target_id: targetsList?.find((i) => i.name === value)?.id,
          commentText: record.comment,
        });
        HostStore.actions.setTableData();
      }}
    />
  );
};

export const getRowClass = (last_active_at: any) => {
  return (
    (new Date().getTime() - new Date(last_active_at).getTime()) /
      (1000 * 60 * 60) >
    24
  );
};
