import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Beacon, NetCatResponseData, getCookie } from "../../shared";
import { UserTokensApi } from "../../Api";
import Collapse, { CollapseProps } from "antd/es/collapse/Collapse";
import NetcatTablePage from "../NetcatTable/NetcatTablePage";
import BeaconTable from "../Beacon/BeaconTable";

const USER_TOKEN_API = new UserTokensApi();

const BinaryInfo = () => {
  const routeParams = useParams();
  const [binaryDetails, setBinaryDetails] = useState<{
    beacons: Beacon[];
    sessions: NetCatResponseData[];
  }>({ beacons: [], sessions: [] });
  const [reRender, setRender] = useState(false);
  const [keys, setKey] = useState<string[]>([]);
  const token = getCookie("token") as string;

  const getTokensUsage = async () => {
    let data = await USER_TOKEN_API.getTokensUsage(`${routeParams?.id}`);
    setBinaryDetails(data);
    setStartKeys(data);
  };

  const setStartKeys = (data: any) => {
    let keys = [];
    if (data?.beacons?.length > 0) {
      keys.push("101");
    }
    if (data?.sessions?.length > 0) {
      keys.push("102");
    }
    setKey(keys);
  };

  const createCollapse = () => {
    let items: CollapseProps["items"] = [
      {
        key: "101",
        label: "Remote sessions",
        children: (
          <NetcatTablePage
            sessions={binaryDetails?.sessions}
            colsType="binaryInfo"
            setIsRerender={setRender}
          />
        ),
      },
      {
        key: "102",
        label: "Beacons",
        children: (
          <BeaconTable
            beacons={binaryDetails?.beacons}
            colsType="binaryInfo"
            setIsRerender={setRender}
          />
        ),
      },
    ];

    return (
      <Collapse
        className="collapse"
        items={items}
        activeKey={keys}
        onChange={(key) => setKey(typeof key === "string" ? [key] : key)}
      />
    );
  };

  useEffect(() => {
    getTokensUsage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeParams]);

  useEffect(() => {
    if (reRender) {
      getTokensUsage();
      setRender(false);
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reRender]);

  return token ? (
    <div className="messages_table">{createCollapse()}</div>
  ) : null;
};

export default BinaryInfo;
