import { createHook, createStore, defaultRegistry } from "react-sweet-state";
import { IBinaryState } from "./IBinaryState";
import { actions } from "./Actions";

export const initialState: IBinaryState = {
  tabledata: [],
  help: "",
  hasEmpty: false,
  isFormOpen: false,
  isBb: false,
  isLoading: false,
  isHelpModal: false,
  showAdvanced: false,
  bbOptions: [],
  checkedValues: [],
  checkedKind: ["bb"],
};

export const Store = createStore<IBinaryState, typeof actions>({
  initialState,
  actions,
});

export const useBinaryState = createHook(Store);

export const BinaryStore = defaultRegistry.getStore(Store);
