import {
    DeleteOutlined,
    FileZipOutlined,
    SaveOutlined
} from '@ant-design/icons'
import { Modal, Tooltip } from 'antd'
import { useTableState } from '../store'
import { DATA_SERVICE } from '../Table'

type Props = {
    record: any
}

export const RenderActions = ({ record }: Props) => {
    const [state, actions] = useTableState();

    const loadFile = async (id: string, fileName: string, isMd: boolean) => {
        if (!state.isLoading) {
            actions.setLoading(!state.isLoading)
            await DATA_SERVICE.loadFile({ id, fileName, isMd }).then(() => actions.setLoading(false));
        }
        actions.setLoading(false)
    }

    const removeFile = async (id: any) => {
        Modal.confirm({
            title: "Are you sure you want to delete the file?",
            okText: "yes",
            okType: "danger",
            onOk: async () => {
                if (!state.isLoading) {
                    actions.setLoading(!state.isLoading)
                    await DATA_SERVICE.removeFileById(id).then(() => {
                        actions.setLoading(false);
                        actions.setRemoved(true)
                    });
                };
                actions.setLoading(false)
            }
        })
    }

    return (
        <>
            <Tooltip title='Download file' color="green">
                <SaveOutlined title='Download file' className='action_icon' style={{ color: "green" }}
                    onClick={() => loadFile(record.id, record.filename, true)} />
            </Tooltip>
            <Tooltip title='Download zip file' color="rgb(74, 74, 245)">
                <FileZipOutlined title='Download zip file' className='action_icon ' style={{ color: "rgb(74, 74, 245)" }}
                    onClick={() => loadFile(record.id, record.filename, false)} />
            </Tooltip>
            <Tooltip title='Delete file' color="red">
                <DeleteOutlined title='Delete file' className='action_icon ' style={{ color: "red" }}
                    onClick={(e) => { e.preventDefault(); removeFile(record.id) }} />
            </Tooltip>
        </>
    )
}
