import { TeamOutlined, UserAddOutlined } from "@ant-design/icons";

const render_action_header = (
    setIsAdding: (value: boolean) => void,
    editClient: () => Promise<void>) => (<>Actions
        <span style={{ float: 'right', marginRight: '10%' }}>
            <UserAddOutlined title="Add new user"
                className="title_btns" style={{ color: 'green' }}
                onClick={() => { setIsAdding(true); }} />
            <TeamOutlined title="Edit client"
                className="title_btns" style={{ color: 'green' }}
                onClick={editClient} />
        </span></>)

const get_user_columns = (
    render_actions: (record: any) => JSX.Element,
    setIsAdding: (value: boolean) => void,
    editClient: () => Promise<void>) => [
        {
            title: "Login",
            dataIndex: "login",
            key: "2",
            width: '10%',
        },
        {
            title: "Roles",
            dataIndex: "roles",
            key: "4",
            width: '25%',
            render: (_text: any, record: any) => { return record.roles?.toString() }
        },
        {
            title: render_action_header(setIsAdding, editClient),
            dataIndex: "Actions",
            key: "Actions",
            render: (_text: any, record: any) => render_actions(record),
            width: '7%',
        },
    ]
export default get_user_columns