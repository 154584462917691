import { AxiosError } from "axios";
import { RemoveTokens, handleError, setTokens } from "../shared";
import { Api } from ".";

export class AuthorizeApi {
  login = async (data: any) => {
    try {
      const response = await Api.post("/api/login", data)
        .then((response: any) => {
          if (response.data.token !== null) {
            setTokens(response.data);
            return response.data;
          }
        })
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  loginByAuthentic = async () => {
    try {
      let response = await Api.get("/api/authentik").then((response: any) => {
        window.location.href = response.data;
        return response;
      })
      return response
    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  postAuthentic = async (params: any) => {
    try {
      const response = await Api.post(`/api/oauth?code=${params.code}&state=${params.state}`)
        .then((response: any) => {
          if (response) {
            setTokens(response.data);
            return response.data;
          }
        })
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }

  }

  logOut = async () => {
    try {
      const response = await Api.post("/api/logout").then((response: any) => {
        RemoveTokens();
        return response;
      });
      RemoveTokens();
      return response;
    } catch (error) {
      RemoveTokens();
      handleError(error as AxiosError);
    }
  }
}