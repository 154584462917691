import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useState } from "react";

export const Legend = () => {
  const [hide, setHide] = useState(true);

  return (
    <>
      {!hide ? (
        <div className="legend">
          <h4>Nodes</h4>
          <div className="internal flex_row">
            <div className="round"></div>
            <div className="label">Private network</div>
          </div>
          <div className="external flex_row">
            <div className="round"></div>
            <div className="label">Public network</div>
          </div>
          <div className="root_host flex_row">
            <div className="round"></div>
            <div className="label">Host with root</div>
          </div>
          <div className="user_host flex_row">
            <div className="round"></div>
            <div className="label">Host with user</div>
          </div>
          <div className="unknown_host flex_row">
            <div className="round"></div>
            <div className="label">Unknown access type</div>
          </div>
          <div className="noaccess_host flex_row">
            <div className="round"></div>
            <div className="label">No access host</div>
          </div>
          <div className="lostaccess_host flex_row">
            <div className="round"></div>
            <div className="label">Lost access host</div>
          </div>
          <div className="gateway flex_row">
            <div className="round"></div>
            <div className="label">Gateway</div>
          </div>
          <div className="linux flex_row">
            <div className="round"></div>
            <div className="label">Linux OS</div>
          </div>
          <div className="windows flex_row">
            <div className="round"></div>
            <div className="label">Windows OS</div>
          </div>
          <div className="darwin flex_row">
            <div className="round"></div>
            <div className="label">Darwin OS</div>
          </div>
          <h4>Edges</h4>
          <div className="interface flex_row">
            <div className="line"></div>
            <div className="label">Interface</div>
          </div>

          <div className="route flex_row">
            <div className="line"></div>
            <div className="label">Route</div>
          </div>
          <div className="gw-route flex_row">
            <div className="line"></div>
            <div className="label">Gateway-route</div>
          </div>
          <div className="gw-route-src flex_row">
            <div className="line"></div>
            <div className="label">Gateway route src</div>
          </div>
          <div className="gw-route-dst flex_row">
            <div className="line"></div>
            <div className="label">Gateway route dst</div>
          </div>
          <div className="btns">
            {" "}
            <Tooltip title="hide legend" color="green">
              <UpOutlined
                onClick={() => {
                  setHide(true);
                }}
                style={{
                  cursor: "pointer",
                  fontSize: "22px",
                }}
              />
            </Tooltip>
          </div>
        </div>
      ) : (
        <div className="hided_legend">
          {"Legend "}
          <Tooltip title="show legend" color="green">
            <DownOutlined
              onClick={() => {
                setHide(false);
              }}
              style={{
                float: "right",
                cursor: "pointer",
                fontSize: "22px",
              }}
            />
          </Tooltip>
        </div>
      )}
    </>
  );
};
