import { getCookie } from "../../shared";

export const getItems = () => {
  const roles = getCookie("roles")?.split(",");
  const items = [
    {
      key: "/ui/home",
      label: (
        <a
          href="/ui/home"
          onClick={(e) => {
            e.stopPropagation();
          }}
          rel="noopener noreferrer"
        >
          Home
        </a>
      ),
    },
    roles?.includes("binary")
      ? {
          key: "/ui/binary",
          label: (
            <a
              href="/ui/binary"
              onClick={(e) => {
                e.stopPropagation();
              }}
              rel="noopener noreferrer"
            >
              Build binary
            </a>
          ),
        }
      : null,
    roles?.includes("target") || roles?.includes("admin")
      ? {
          key: "/ui/targets",
          label: (
            <a
              href="/ui/targets"
              onClick={(e) => {
                e.stopPropagation();
              }}
              rel="noopener noreferrer"
            >
              Targets list
            </a>
          ),
        }
      : null,
    {
      key: "Target",
      label: "Target",
      children: [
        roles?.includes("beacon") || roles?.includes("admin")
          ? {
              key: "/ui/beacon",
              label: (
                <a
                  href="/ui/beacon"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  rel="noopener noreferrer"
                >
                  Beacon
                </a>
              ),
            }
          : null,
        roles?.includes("collector") || roles?.includes("admin")
          ? {
              key: "/ui/table",
              label: (
                <a
                  href="/ui/table"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  rel="noopener noreferrer"
                >
                  Collection results
                </a>
              ),
            }
          : null,
        roles?.includes("credentials") || roles?.includes("admin")
          ? {
              key: "/ui/credentials",
              label: (
                <a
                  href="/ui/credentials"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  rel="noopener noreferrer"
                >
                  Credentials
                </a>
              ),
            }
          : null,
        roles?.includes("session") || roles?.includes("admin")
          ? {
              key: "/ui/gs-netcat",
              label: (
                <a
                  href="/ui/gs-netcat"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  rel="noopener noreferrer"
                >
                  Remote sessions
                </a>
              ),
            }
          : null,

        roles?.includes("target-host") || roles?.includes("admin")
          ? {
              key: "/ui/target-host",
              label: (
                <a
                  href="/ui/target-host"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  rel="noopener noreferrer"
                >
                  Target hosts
                </a>
              ),
            }
          : null,

        roles?.includes("target-host") || roles?.includes("admin")
          ? {
              key: "/ui/cmd",
              label: (
                <a
                  href="/ui/cmd"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  rel="noopener noreferrer"
                >
                  Commands
                </a>
              ),
            }
          : null,
      ],
    },

    roles?.includes("admin") || roles?.includes("client")
      ? {
          key: "Admin",
          label: "Admin",
          children: [
            roles?.includes("admin")
              ? {
                  key: "/ui/clients",
                  label: (
                    <a
                      href="/ui/clients"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      rel="noopener noreferrer"
                    >
                      Clients
                    </a>
                  ),
                }
              : null,
            roles?.includes("client") || roles?.includes("admin")
              ? {
                  key: "/ui/users",
                  label: (
                    <a
                      href="/ui/users"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      rel="noopener noreferrer"
                    >
                      Users
                    </a>
                  ),
                }
              : null,
            roles?.includes("admin")
              ? {
                  key: "/ui/server-tokens",
                  label: (
                    <a
                      href="/ui/server-tokens"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      rel="noopener noreferrer"
                    >
                      Server tokens
                    </a>
                  ),
                }
              : null,
          ],
        }
      : null,
    roles?.includes("beacon") ||
    roles?.includes("admin") ||
    roles?.includes("session")
      ? {
          key: "/ui/statistics",
          label: (
            <a
              href="/ui/statistics"
              onClick={(e) => {
                e.stopPropagation();
              }}
              rel="noopener noreferrer"
            >
              Statistics
            </a>
          ),
        }
      : null,
  ];

  return items;
};
