import { FilterValue, Key } from "antd/es/table/interface";
import { Beacon, Filters, NetCatResponseData } from "..";

export const filterData = (
  data: Array<Beacon | NetCatResponseData>,
  filters: Filters
): Array<Beacon | NetCatResponseData> => {
  const filterKeys: string[] = Object.keys(filters).filter((i) => filters[i]);
  const filteredData: Array<Beacon | NetCatResponseData> = data.filter((item) =>
    _filterData(item, filterKeys, filters)
  );
  return filteredData;
};

const _filterData = (
  item: Beacon | NetCatResponseData,
  filterKeys: string[],
  filters: Filters
): boolean => {
  return filterKeys.every((filterKey: string) =>
    __checkFilterKey(
      filters[filterKey] as FilterValue,
      filterKey,
      item[filterKey]
    )
  );
};

const __checkFilterKey = (
  filterValue: FilterValue,
  filterKey: string,
  itemValue: any
): boolean => {
  return filterValue.some((value: boolean | Key) =>
    ___checkFilterValue(itemValue, filterKey, value)
  );
};

const ___checkFilterValue = (
  itemValue: any,
  filterKey: string,
  filterValue: boolean | Key
): boolean => {
  if (filterKey === "created_at" || filterKey === "updated_at") {
    return (
      Math.round(
        Math.floor(
          (Date.now() - new Date(itemValue.Time).getTime()) / (1000 * 60)
        )
      ) <= (filterValue as number)
    );
  }
  if (typeof itemValue === "string") {
    return filterValue.toString().split(":")[0] === itemValue.split(":")[0];
  }
  if (Array.isArray(itemValue)) {
    return itemValue.includes(filterValue);
  }
  return false;
};

export const customNotIncludesFilter = (
  field: any,
  value: string,
  filter?: any
) => {
  return filter && filter?.includes("not includes")
    ? Array.isArray(field)
      ? !filter?.some((v: any) => field?.includes(v))
      : !filter?.includes(field)
    : filter?.includes("")
    ? field?.length === 0
    : field?.includes(value);
};

export const defaultFilter = ["not includes"];
