import React from "react";
import { useCredsState } from "../Store";
import { Tooltip } from "antd";
import { SnippetsFilled } from "@ant-design/icons";
import cls from "../creds.module.css";
import { downloadSelectedKeys } from "../actions";
import {
  ColumnSelector,
  SearchTargets,
  TableSettings,
  TargetContext,
} from "../../shared";
import { ColumnsType } from "antd/es/table";

type Props = {
  show_search: boolean;
  columns: {
    columns: ColumnsType<any>;
    column_names: string[];
  };
};

export const SearchModule = ({ show_search, columns }: Props) => {
  const [state, actions] = useCredsState();
  const { targetsList } = React.useContext(TargetContext)!;

  return !show_search ? (
    <>
      {state.selectedRowKeys.length > 0 && (
        <Tooltip color="rgb(222 169 230)" title="Download selected keys">
          <SnippetsFilled
            className={cls.downloadZip}
            onClick={downloadSelectedKeys}
          />
        </Tooltip>
      )}
    </>
  ) : (
    <>
      <SearchTargets
        downloadSelectedKeys={
          state.selectedRowKeys.length > 0 ? downloadSelectedKeys : undefined
        }
        getData={actions.setTableData}
        type={"creds"}
        typesList={state.sources}
        tableData={state.tabledata}
      />
      <TableSettings>
        <div className={cls.cred_selectors}>
          <div style={{ width: "90%" }}>
            <span className="copy_reminder">
              * Double click on the Password, Password hash or CMD field to copy
              value
            </span>
          </div>
          <ColumnSelector
            tabledata={state.tabledata}
            targetsList={targetsList}
            columns={columns}
            selected_values={
              state.selectedColumns?.map((item) =>
                item.title?.toString()
              ) as string[]
            }
            cookie_name={show_search ? "creds_columns" : "creds_target"}
            setSelectedColumns={actions.setSelectedColumns}
          />
        </div>
      </TableSettings>
    </>
  );
};
