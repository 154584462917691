import { UserAddOutlined } from "@ant-design/icons";

const render_action_header = (setIsAdding: (value: boolean) => void) => {
    return (<>Actions
        <UserAddOutlined
            title="add new client"
            className="title_btns"
            style={{ float: "right", marginRight: "10%", color: 'green' }}
            onClick={() => { setIsAdding(true); }} />
    </>)
}

export const get_client_columns = (
    render_actions: (record: any) => JSX.Element,
    setIsAdding: (value: boolean) => void) => [
        {
            title: "Name",
            dataIndex: "name",
            key: "2",
            width: '10%',
        },
        {
            title: "Allowed binaries",
            dataIndex: "allowed_binaries",
            key: "3",
            width: '25%',
            render: (_text: any, record: any) => record.allowed_binaries.toString()

        },
        {
            title: render_action_header(setIsAdding),
            dataIndex: "Actions",
            key: "Actions",
            render: (_text: any, record: any) => render_actions(record),
            width: '7%',
        },
    ]
