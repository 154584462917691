import { MenuStore } from "../store";

const moon = require('../../../images/icons8-moon-48.png')
const sun = require('../../../images/icons8-sun-48.png')
const earth = require('../../../images/earth.png')

export const ThemeIcon = (): JSX.Element => {
    const themeIcon: string = (() => {
        switch (MenuStore.storeState.getState().theme) {
            case 'light': return sun;
            case 'dark': return moon;
            case 'earth': return earth;
            default: return sun;
        }
    })();

    return <img id='icon' onClick={MenuStore.actions.changeTheme} src={themeIcon} alt="" className="sun" />
};