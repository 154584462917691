import React, { useEffect } from "react";
import cls from "./home.module.css";

const HomePage: React.FC = () => {
  useEffect(() => {
    document.title = "BartUI";
  }, []);

  return (
    <div className={cls.home_main}>
      {/* <div className={cls.bg_image}><img src={pitbull} alt="" className={cls.owl} /></div> */}
    </div>
  );
};
export default HomePage;
