import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthorizeApi, ClientsApi } from "../../Api";
import { User, getCookie } from "../../shared";
import ManageUserModal from "../AdminPage/Modals/ManageUserModal";
import { useAdminState } from "../AdminPage/Store";
import { TargetContext } from "../shared";
import { getItems } from "./items";

import { useMenuState } from "./store";
import cls from "./styles.module.css";
import { Theme } from "./ui";

const logo = require("../../images/phenix.png");

// const christmas = require('../../images/christmas_6.png')
// const icicles = require('../../images/icicles-4.png')
const { Header } = Layout;

const AUTH_SERVICE = new AuthorizeApi();
const USERS_SERVICE = new ClientsApi();

const SideMenu: React.FC = () => {
  const token = getCookie("token") as string;
  const currentUserId = getCookie("currentUserId") as string;
  const { initTargets } = React.useContext(TargetContext)!;
  const [menustate, menuactions] = useMenuState();
  const [state, actions] = useAdminState();
  const navi = useNavigate();

  const getCurrentUser = async () => {
    actions.setCurrentUser(await USERS_SERVICE.getUserById(currentUserId));
  };

  const updateUser = async (user: User) => {
    await USERS_SERVICE.updateUser(user);
    getCurrentUser();
  };

  const navigateTabs = ({ key }: { key: string }) => {
    if (key === "/ui/users") {
      navi(`${key}/${state.current_user.client_id}`);
    } else {
      navi(key);
      menuactions.setKey(key);
    }
  };

  useEffect(() => {
    !["/", "/ui/oauth"].includes(window.location.pathname) &&
      !state.current_user.id &&
      getCurrentUser();
    if (
      token &&
      !["clients", "users", "server-tokens", "cmd", "targets"].some((i) =>
        window.location.pathname.includes(i)
      )
    ) {
      initTargets();
    }
    menuactions.setSelectedKeys();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId, menustate.key, window.location.pathname]);

  useEffect(() => {
    menuactions.initTheme();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!token) return null;

  return !["/", "/ui/oauth"].includes(window.location.pathname) ? (
    <Layout className="layout">
      <Header className={cls.header_module}>
        {/* <img className="icicles" src={icicles} alt="" ></img> */}
        <div className="demo-logo" />
        <img
          src={logo}
          className="Assasin"
          alt=""
          onClick={() => navi("/ui/home")}
        />
        <Menu
          theme="dark"
          mode="horizontal"
          onClick={navigateTabs}
          selectedKeys={menustate.selectedKeys}
          items={getItems()}
        ></Menu>
        <Theme />
        <UserOutlined
          title="Edit user"
          onClick={() => actions.setShowTokens(true)}
        />
        <LogoutOutlined
          title="LogOut"
          className="logOutButton"
          onClick={AUTH_SERVICE.logOut}
        />
        {/* <img className="icicles-right" src={icicles} alt="" ></img> */}
      </Header>
      <ManageUserModal
        isOpen={state.showTokens}
        width={1050}
        updateUser={updateUser}
      />
    </Layout>
  ) : (
    <></>
  );
};

export default SideMenu;
