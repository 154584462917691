import { Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthorizeApi } from "../../Api";
import { clearCacheData, getCookie } from "../../shared";
import "./styles.css";

let authService = new AuthorizeApi();

const SignIn: React.FC = () => {
  const navi = useNavigate();
  const token = getCookie("token") as string;
  const [form] = Form.useForm();
  const [image, setImage] = useState<{ image: string; key: string }>();

  const handler = {
    form: {
      submit: async (values: any) => {
        let response = await authService.login(values);
        setImage({
          image: response?.image ? response?.image : undefined,
          key: response?.secret_key,
        });
        const token = getCookie("token") as string;
        token && clearCacheData();
        token ? navi("/ui/home") : form.resetFields();
      },
    },
  };

  const validator = {
    Password: [{ required: true, message: "input is empty" }],
    Code: [
      {
        pattern: /^\d{6}$/,
        message: "must consist of 6 numbers",
      },
    ],
  };

  const initialValues = {
    Login: "",
    Password: "",
  };

  const loginByAuthentic = async () => {
    await authService.loginByAuthentic();
  };

  useEffect(() => {
    token?.length > 0 && navi("/ui/home");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div style={{ overflow: "hidden" }}>
      <Form
        layout="vertical"
        name="SignInForm"
        initialValues={initialValues}
        form={form}
        onFinish={handler.form.submit}
      >
        <Form.Item label="Login" name="login">
          <Input placeholder="Enter Login" />
        </Form.Item>
        <Form.Item label="Password" name="password" rules={validator.Password}>
          <Input.Password
            visibilityToggle={true}
            placeholder="Enter password"
          />
        </Form.Item>
        <Form.Item label="Access key" name="totp" rules={validator.Code}>
          <Input autoComplete="off" placeholder="Enter access key" />
        </Form.Item>
        <Button htmlType="submit" className="arch" style={{ width: "132px" }}>
          Enter
        </Button>
        <Button
          className="arch"
          onClick={loginByAuthentic}
          style={{ float: "right" }}
        >
          Authentik
        </Button>
      </Form>
      {image?.image && (
        <div>
          <p style={{ marginLeft: "20%", marginTop: "1%" }}>
            Two factor auth was enabled for Your account. <br />
            To proceed scan QR code or enter secret{" "}
            <span style={{ color: "red" }}>{image.key}</span> <br /> manually to
            app of Your choice
          </p>
          <img
            style={{ width: "150px", height: "150px", marginLeft: "20%" }}
            src={`data:image.png;base64,${image.image}`}
            alt=""
          />
        </div>
      )}
    </div>
  );
};
export default SignIn;
