import React, { useEffect, useLayoutEffect } from "react";

import { FloatButton, Table } from "antd";

import {
  NetCatResponseData,
  getCookie,
  pagination_options,
} from "../../shared";
import { SearchTargets, TargetContext } from "../shared";
import { useNetcatState } from "./Store";
import {
  genDownloadData,
  handleChange,
  handleFilter,
  showInfoModal,
} from "./actions";
import { Get_netcat_columns } from "./ui";
import TopSection from "./ui/TopSection";

type Props = {
  sessions?: NetCatResponseData[];
  setIsRerender?: (isRerender: boolean) => void;
  colsType?: string;
  id?: string;
};

const NetcatTablePage: React.FC<Props> = ({
  sessions,
  setIsRerender,
  colsType,
  id,
}) => {
  const token = getCookie("token") as string;
  const { targetsList } = React.useContext(TargetContext)!;
  const [state, actions] = useNetcatState();

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (state.isActive && !sessions && !id && !state.isLoading) {
      interval = setInterval(() => {
        actions.setTableData();
      }, 10 * 1000);
      return () => {
        clearInterval(interval);
      };
    } else {
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isActive, state.isLoading]);

  useLayoutEffect(() => {
    if (!sessions && !id) {
      handleFilter(state.tabledata);
      document.title = "Sessions / BartUI ";
    }
    actions.setTableData(sessions, id);
    actions.setTypesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    state.checkSwith && actions.setTableData(sessions, id);
    actions.setCheckSwith(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.checkSwith]);

  useEffect(() => {
    handleFilter(state.tabledata);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filteredInfo, state.tabledata]);

  return !token ? (
    <></>
  ) : (
    <div className={sessions || id ? "" : "messages_table"}>
      {!sessions && !id ? (
        <TopSection targetsList={targetsList} />
      ) : id ? (
        <>
          <SearchTargets
            getData={() => {
              actions.setTableData(sessions, id);
            }}
            type={"target_net"}
            typesList={state.typesList}
            tableData={genDownloadData()}
          />
        </>
      ) : (
        <></>
      )}
      <Table
        scroll={
          (sessions || id) && colsType === "hostInfo"
            ? undefined
            : { x: state.selectedColumns.length > 9 ? 1900 : 1200 }
        }
        className={sessions || id ? "credsTable" : "netCatTable"}
        rowKey="id"
        columns={
          sessions || id
            ? Get_netcat_columns(colsType, targetsList, setIsRerender).columns
            : state.selectedColumns
        }
        dataSource={sessions ? sessions : state.tabledata}
        pagination={
          (sessions && sessions?.length < 50) ||
          (id && state.tabledata.length < 50)
            ? false
            : pagination_options
        }
        onChange={(_, filter, sorter, extra) => {
          actions.setFilteredInfo(filter);
          handleChange(sorter, sessions);
          actions.setFilteredData(extra.currentDataSource);
        }}
        onRow={(record, _) => {
          return {
            onDoubleClick: (event) => {
              showInfoModal(record);
            },
          };
        }}
      />
      <FloatButton.BackTop />
    </div>
  );
};

export default NetcatTablePage;
