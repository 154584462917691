import { createHook, createStore } from "react-sweet-state";
import { ITableState } from "./ITableState";
import { actions } from "./Actions";

const initialState: ITableState = {
  tabledata: [],
  isLoading: false,
  collection_analysis: [],
  selectedRowKeys: [],
  isRemoved: false,
};

export const Store = createStore<ITableState, typeof actions>({
  initialState,
  actions,
});

export const useTableState = createHook(Store);
