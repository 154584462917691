import { useEffect } from "react";

import { Button, FloatButton, Table, Tooltip } from "antd";

import cls from "./creds.module.css";

import React from "react";
import {
  CredentialModel,
  getCookie,
  pagination_options,
  setCookie,
} from "../../shared";
import { TargetContext, get_rowSelection } from "../shared";
import { useCredsState } from "./Store";
import { EditCredModal, GetcredsColunms, SearchModule } from "./ui";
import { DownloadOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { AddCredModal } from "./ui/AddCredModal";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";

type Props = {
  targetId?: string;
  hostId?: string;
  hostCreds?: CredentialModel[];
  setRender?: (isRerender: boolean) => void;
  colsType?: string;
};

const CredentialsPage: React.FC<Props> = ({
  targetId,
  hostId,
  hostCreds,
  setRender,
  colsType,
}) => {
  const token = getCookie("token") as string;
  const [state, actions] = useCredsState();
  const { targetsList } = React.useContext(TargetContext)!;

  const navi = useNavigate();

  const fn = () => {
    (hostCreds || targetId) && setRender!(true);
    actions.setTableData(hostCreds, targetId);
  };

  const pagination = {
    ...pagination_options,
    current: state.currentPage,
    onChange: (cur: number) => actions.setCurrentPage(cur),
  };

  const handleChange = (sorter: any) => {
    setCookie(
      !hostCreds ? "creds_columns_sorter" : "creds_target_sorter",
      `${sorter.columnKey},${sorter.order}`,
      365
    );
    actions.changeSelectedColumnsSorting([
      `${sorter.columnKey}`,
      `${sorter.order}`,
    ]);
  };

  useEffect(() => {
    if (!hostCreds && !targetId) {
      document.title = "Credentials / BartUI ";
      actions.setSources();
    }
    actions.setTableData(hostCreds, targetId);
    hostCreds &&
      actions.setSelectedColumns(
        GetcredsColunms(state.tabledata, targetsList, fn, colsType, navi)
          .columns
      );

    actions.changeSelectedColumnsSorting(
      undefined,
      !hostCreds ? "creds_columns_sorter" : "creds_target_sorter"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostCreds, targetId]);

  return !token ? (
    <></>
  ) : (
    <div
      className={hostCreds || targetId ? "" : "messages_table"}
      style={{ position: "relative", marginTop: targetId ? "55px" : "" }}
    >
      {(hostCreds || targetId) && (
        <>
          <Tooltip title="Download CSV">
            <CSVLink
              filename="creds"
              className={cls.downloadCsvHost}
              data={state.tabledata}
            >
              <DownloadOutlined />
            </CSVLink>
          </Tooltip>
          <Button
            className="add_creds_btn"
            icon={
              <PlusSquareOutlined
                style={{ color: "green", fontSize: "22px" }}
              />
            }
            onClick={() => actions.setAdding(true)}
          >
            Add credentials{" "}
          </Button>
          <AddCredModal targetId={targetId} hostId={hostId} />
        </>
      )}
      <EditCredModal targetsList={targetsList} hostCreds={hostCreds} fn={fn} />

      {!targetId && (
        <SearchModule
          show_search={hostCreds === undefined}
          columns={GetcredsColunms(
            state.tabledata,
            targetsList,
            fn,
            colsType,
            navi
          )}
        />
      )}

      <Table
        scroll={{ x: 1800 }}
        className={cls.credsTable}
        columns={
          targetId
            ? GetcredsColunms(state.tabledata, targetsList, fn, colsType, navi)
                .columns
            : state.selectedColumns
        }
        dataSource={state.tabledata}
        pagination={pagination}
        rowSelection={get_rowSelection(state, actions)}
        rowKey="id"
        rowClassName={(record) => (record.favorite ? "favoriteRow" : "")}
        onChange={(_, filter, sorter, extra) => handleChange(sorter)}
      />
      <FloatButton.BackTop />
    </div>
  );
};
export default CredentialsPage;
