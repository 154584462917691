import { useEffect, useRef } from "react";
import { TokenApi } from "../../../Api";
import { getCookie } from "../../../shared";
import { Input, InputRef, Modal, Table } from "antd";
import get_token_columns from "./columns";
import { DeleteOutlined, FolderAddOutlined } from "@ant-design/icons";
import { AxiosError } from "axios";
import TextArea from "antd/es/input/TextArea";
import { useAdminState } from "../Store";

const TOKEN_CLIENT = new TokenApi();

const TokenTable = () => {
  const token = getCookie("token") as string;
  const [state, actions] = useAdminState();
  const ref = useRef<InputRef>(null);

  const getData = async () => {
    actions.setServerTokens(await TOKEN_CLIENT.getTokensList());
  };

  const deleteToken = async (record: any) => {
    await TOKEN_CLIENT.deleteTokenById(record.id);
    getData();
  };

  const addNewServer = () => {
    Modal.confirm({
      icon: <FolderAddOutlined />,
      className: "add_server_modal",
      title: "Add new server name",
      content: <Input ref={ref} style={{ marginLeft: "-35px" }} />,
      width: 450,
      okText: "Save",
      onCancel: () => {},
      onOk: async () => {
        if (
          ref.current?.input?.value &&
          ref.current?.input?.value?.length > 0
        ) {
          try {
            let response = await TOKEN_CLIENT.addNewToken(
              ref.current?.input?.value
            );
            response && showToken(response);
          } catch (error) {
            if ((error as AxiosError).response?.status === 406) {
              let modal = Modal.error({
                title: "A server with the specified name already exists",
              });
              setTimeout(modal.destroy, 2 * 1000);
            }
          }
          getData();
        } else {
          let modal = Modal.error({
            title: "Empty input",
          });
          setTimeout(modal.destroy, 2 * 1000);
        }
      },
    });
  };

  const showToken = (token: any) => {
    Modal.info({
      title:
        "Copy before closing this window - you won't be able to access token afterwards",
      width: 650,
      content: (
        <TextArea
          autoSize
          style={{ border: "none", resize: "none", width: "100%" }}
          readOnly
          value={token.token}
        />
      ),
    });
  };

  const render_actions = (record: any) => (
    <>
      <DeleteOutlined
        onClick={() => {
          deleteToken(record);
        }}
        title="Delete"
        style={{ color: "red", marginLeft: "16px", fontSize: "19px" }}
      />
    </>
  );

  useEffect(() => {
    getData();
    document.title = "Tokens / BartUI ";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !token ? (
    <></>
  ) : (
    <div className="messages_table">
      <Table
        className="tokens_table"
        columns={get_token_columns(
          render_actions,
          addNewServer,
          state.serverToken
        )}
        dataSource={state.serverToken}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "40", "60"],
        }}
        rowKey="id"
      />
    </div>
  );
};

export default TokenTable;
