import { ColumnsType, SorterResult } from "antd/es/table/interface";
import {
  Filters,
  NetCatResponseData,
  Targets,
  actionsType,
  generateConnectionString,
  getCache,
  setCache,
} from "../../../shared";
import { INetcatState } from "./INetcatState";
import AuthLocalStorage from "../../../AuthLocalStorage";
import { NetcatApi } from "../../../Api";

const CLIENT = new NetcatApi();

export const actions = {
  setActive:
    (isActive: boolean) =>
    ({ setState, getState }: actionsType<INetcatState>) =>
      setState({ ...getState(), isActive }),

  setLoading:
    (isLoading: boolean) =>
    ({ setState, getState }: actionsType<INetcatState>) =>
      setState({ ...getState(), isLoading }),

  setTableData:
    (sessions?: NetCatResponseData[], id?: string) =>
    async ({ setState, getState }: actionsType<INetcatState>) => {
      let tabledata;

      if (!!sessions?.length) {
        tabledata = sessions;
      } else {
        actions.setLoading(true);
        let target =
          !id && AuthLocalStorage.getTargetSelects()
            ? JSON.parse(AuthLocalStorage.getTargetSelects()!)
            : id && AuthLocalStorage.getTargetNetSelects()
            ? JSON.parse(AuthLocalStorage.getTargetNetSelects()!)
            : new Targets();
        target.type = target.type === "all" ? "" : target.type;
        if (id) {
          target.target_id = id;
        }
        actions.setFilteredInfo({
          ...getState().filteredInfo,
          type: target.type?.length > 0 ? [target.type] : null,
          target:
            target.target === "all" || target.target === undefined
              ? null
              : [target.target],
        });
        let response = await CLIENT.getData(target);
        tabledata = response?.map((x: NetCatResponseData) => {
          x.client = x.client ? x.client : "";
          x.connection_string = generateConnectionString(x);
          return x;
        });
        actions.setLoading(false);
      }
      setState({ ...getState(), tabledata });
    },

  deleteTableRecord:
    (recordId: string) =>
    ({ setState, getState }: actionsType<INetcatState>) => {
      const tabledata = [
        ...getState().tabledata.filter((record) => record.id !== recordId),
      ];
      setState({ ...getState(), tabledata });
    },

  setFilteredData:
    (filteredData: NetCatResponseData[]) =>
    ({ setState, getState }: actionsType<INetcatState>) =>
      setState({ ...getState(), filteredData }),

  setFilteredInfo:
    (filteredInfo: Filters) =>
    ({ setState, getState }: actionsType<INetcatState>) =>
      setState({ ...getState(), filteredInfo }),

  setTypesList:
    () =>
    async ({ setState, getState }: actionsType<INetcatState>) => {
      let types = await getCache("sessionTypes");
      let typesList: string[] = [];
      typesList = types?.length === 0 ? await CLIENT.getSessionsTypes() : types;
      types?.length === 0 && setCache(typesList, "sessionTypes");

      setState({ ...getState(), typesList });
    },

  setSelectedColumns:
    (selectedColumns: ColumnsType<NetCatResponseData>) =>
    ({ setState, getState }: actionsType<INetcatState>) =>
      setState({ ...getState(), selectedColumns }),

  changeSelectedColumnsSorting:
    (sorter: SorterResult<NetCatResponseData>) =>
    ({ setState, getState }: actionsType<INetcatState>) => {
      const selectedColumns = [
        ...getState().selectedColumns.map((col) => {
          if (col.key === sorter.columnKey) {
            col.sortOrder = sorter.order;
          } else {
            col.sortOrder = undefined;
          }
          return col;
        }),
      ];
      setState({ ...getState(), selectedColumns });
    },

  setCheckSwith:
    (checkSwith: boolean) =>
    ({ setState, getState }: actionsType<INetcatState>) =>
      setState({ ...getState(), checkSwith }),
};
