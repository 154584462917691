import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import ClientsPage from './Components/AdminPage/ClientPage';
import UserPage from './Components/AdminPage/users/UserPage';
import AuthPage from './Components/AuthPage/AuthPage';
import BeaconTable from './Components/Beacon/BeaconTable';
import MessagesTable from './Components/Beacon/MessagesTable/MessagesTable';
import BinaryTable from './Components/Binary/BinaryTable';
import CredentialsPage from './Components/CredentialsPage/CredentialsPage';
import HomePage from './Components/HomePage/HomePage';
import NetcatTablePage from './Components/NetcatTable/NetcatTablePage';
import SideMenu from './Components/SideMenu/SideMenu';
import DataTable from './Components/Table/Table';
import TokenTable from './Components/AdminPage/TokenTable/TokenTable';
import TargetHostPage from './Components/TargetHosts/TargetHostPage';
import HostInfoPage from './Components/TargetHosts/HostInfo/HostInfoPage';
import OAuth from './Components/OAuth/OAuth';
import TargetsPage from './Components/TargetsPage/TargetsPage';
import TargetInfoPage from './Components/TargetsPage/TargetInfo/TargetInfoPage';
import TargetProvider from './Components/shared/TargetContext/TargetContext';
import BinaryInfo from './Components/BinaryInfo/BinaryInfo';
import CMDPage from './Components/CMDPage/CMDPage';
import HostCmdInfo from './Components/CMDPage/HostCmdInfo';
import NodeInfo from './Components/Notes/NodeInfo';
import Statistics from './Components/Statistics/Statistics';
import packageJson from '../package.json';

const App: React.FC = () => {
  console.log('version: ', packageJson.version);
  return (
    <div className=''>
      <TargetProvider>
        <BrowserRouter>
          <SideMenu />
          <Routes>
            <Route path="/" element={<AuthPage />}></Route>
            <Route path="/ui/oauth" element={<OAuth />} />
            <Route path="/ui/home" element={<HomePage />}></Route>
            <Route path="/ui/table" element={<DataTable />}></Route>
            <Route path="/ui/binary" element={<BinaryTable />}></Route>
            <Route path="/ui/gs-netcat" element={<NetcatTablePage />}></Route>
            <Route path="/ui/beacon" element={<BeaconTable />}>  </Route>
            <Route path="/ui/beacon/:id/messages/" element={<MessagesTable />} />
            <Route path="/ui/users/:id" element={<UserPage />}></Route>
            <Route path="/ui/clients" element={<ClientsPage />}></Route>
            <Route path="/ui/credentials" element={<CredentialsPage />}></Route>
            <Route path="/ui/server-tokens" element={<TokenTable />}></Route>
            <Route path="/ui/target-host" element={<TargetHostPage />}></Route>
            <Route path="/ui/target-host/:id/info/" element={<HostInfoPage />} />
            <Route path="/ui/targets" element={<TargetsPage />} />
            <Route path="/ui/target/:id/info/" element={<TargetInfoPage />} />
            <Route path="/ui/binary/:id/info" element={<BinaryInfo />} />
            <Route path="/ui/cmd" element={<CMDPage />} />
            <Route path="/ui/cmd/:id/info" element={<HostCmdInfo />} />
            <Route path="/ui/target/:id/note/:node_id" element={<NodeInfo />} />
            <Route path="/ui/statistics" element={<Statistics />} />
          </Routes>
        </BrowserRouter>
      </TargetProvider>
      {/* <div className='footer'></div> */}
    </div>
  );
}

export default App;
