import { useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { TargetStore } from "../Store";
import {
  customNotIncludesFilter,
  defaultFilter,
  getFilterOptions,
  onlyUnique,
  renderDate,
} from "../../../shared";
import { iconFilter, renderTools } from "../../shared";
import { renderActions, renderFileLink } from "../actions";

export const TargetColumns = () => {
  const navi = useNavigate();
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value: any, record: any) => (
        <a
          href={`/ui/target/${record.id}/info`}
          onClick={(e) => {
            e.preventDefault();
            navi(`/ui/target/${record.id}/info`);
          }}
        >
          {value}
        </a>
      ),
      width: 150,
      filters: getFilterOptions([
        ...defaultFilter,
        ...TargetStore.storeState
          .getState()
          .tabledata?.map((x: any) => x.name)
          ?.filter(onlyUnique),
      ]),
      filterSearch: true,
      onFilter: (value: any, record: any) => {
        return customNotIncludesFilter(
          record.name,
          value,
          TargetStore.storeState.getState().filters?.name
        );
      },
    },
    {
      title: "has alerts",
      dataIndex: "has_alerts",
      key: "has_alerts",
      width: 70,
      render: (value: boolean, record: any) =>
        value && (
          <div
            className="round"
            style={{
              backgroundColor: "orange",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.preventDefault();
              navi(`/ui/target/${record.id}/info`);
            }}
          ></div>
        ),
    },
    {
      title: "Hosts number",
      dataIndex: "hosts_number",
      key: "hosts_number",
      width: 70,
    },
    {
      title: "Tools",
      dataIndex: "beacons",
      key: "beacons",
      render: (value: any, record: any) =>
        renderTools(record.beacons, record.remote_sessions),
      filters: iconFilter(TargetStore.storeState.getState().tabledata),
      filterSearch: true,
      ellipsis: {
        showTitle: false,
      },
      onFilter: (value: any, record: any) => {
        return TargetStore.storeState
          .getState()
          .filters?.beacons?.includes("not includes")
          ? customNotIncludesFilter(
              record.beacons,
              value,
              TargetStore.storeState.getState().filters?.beacons
            ) &&
              customNotIncludesFilter(
                record.remote_sessions,
                value,
                TargetStore.storeState.getState().filters?.beacons
              )
          : customNotIncludesFilter(
              record.beacons,
              value,
              TargetStore.storeState.getState().filters?.beacons
            ) ||
              customNotIncludesFilter(
                record.remote_sessions,
                value,
                TargetStore.storeState.getState().filters?.beacons
              );
      },
      width: 230,
    },
    {
      title: "Files",
      dataIndex: "storage_url",
      key: "storage_url",
      ellipsis: {
        showTitle: false,
      },
      render: (value: any, record: any) => renderFileLink(record.storage_url),
      width: 100,
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at: any) => renderDate(created_at),
      width: 180,
    },
    {
      title: "Updated at",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (updated_at: any) => renderDate(updated_at),
      width: 180,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_text: any, record: any) => renderActions(record, navi),
      width: 150,
      fixed: "right",
    },
  ] as ColumnsType<any>;
};
