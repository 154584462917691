import { Collapse, CollapseProps, Empty } from "antd";
import { getCookie } from "../../../shared";
import TargetHostPage from "../../TargetHosts/TargetHostPage";
import { useTargetState } from "../Store";

export const Network = ({ id }: { id?: string }) => {
  const [state, actions] = useTargetState();
  const token = getCookie("token") as string;

  const createCollapse = () => {
    if (state.isHostTable) {
      let items: CollapseProps["items"] = Object?.keys(state.hosts)?.map(
        (key) => {
          return {
            key: `${key}`,
            label: key,
            children: state.targetDetails?.id === id && (
              <TargetHostPage
                hostInfo={state.hosts[key]}
                setIsRerender={actions.setIsRerender}
              />
            ),
          };
        }
      );

      return (
        <Collapse
          className="collapse"
          items={items}
          activeKey={state.hostCollapse}
          onChange={(key) => {
            actions.setHostCollapse(typeof key === "string" ? [key] : key);
          }}
        />
      );
    } else {
      return state.hosts && state.hosts[0]?.target_id === id ? (
        <TargetHostPage
          hostInfo={state.hosts}
          setIsRerender={actions.setIsRerender}
        />
      ) : (
        <Empty />
      );
    }
  };

  if (!token) return null;
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: "10px",
        }}
      >
        <p style={{ width: "100px", display: "inline-block" }}>
          {"Show networks"}
        </p>
        <div
          className={`toggle ${state.isHostTable ? "active" : ""}`}
          onClick={() => {
            actions.setHosts([]);
            actions.setIsHostTable(!state.isHostTable);
          }}
        >
          <div />
        </div>
      </div>
      {createCollapse()}
    </div>
  );
};
