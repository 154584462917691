import { DownSquareOutlined, UpSquareOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { expandAll, hideExpand } from '.'

export const ExpandBtns = () => {
    return (<>
        <Tooltip title='Expand all' color='blue'>
            <DownSquareOutlined className='exp_btns' onClick={expandAll} />
        </Tooltip>
        <Tooltip title='Roll up all' color='purple'>
            <UpSquareOutlined className='exp_btns' onClick={hideExpand} />
        </Tooltip>
    </>
    )
}

