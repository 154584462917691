import { AddCredFormFields } from "../ui/AddCredModal";

export class AddCredentialsFromUI {
    service: string;
    login: string;
    password: string;
    password_hash: string;
    key: string;
    comment: string;
    source: string;
    
    constructor(userData: AddCredFormFields) {
        this.service = userData.service || '';
        this.login = userData.login || '';
        this.password = userData.password || '';
        this.password_hash = userData.password_hash || '';
        this.key = userData.key || '';
        this.comment = userData.comment || '';
        this.source = 'ui';
    }
}
