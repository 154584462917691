export class BuildModel {
  kind: string;
  target_os: string;
  target_arch: string;
  tags: string[];
  proxy: string;
  logging: string;
  parts?: string[];
  args?: string;
  compress: boolean;
  perm_url: boolean;
  target_id: string;
  server_ip?: string;
  domain?: string;
  constructor() {
    this.kind = "bb";
    this.parts = [];
    this.target_os = "linux";
    this.target_arch = "amd64";
    this.tags = [];
    this.proxy = "";
    this.logging = "console";
    this.args = "";
    this.compress = true;
    this.perm_url = false;
    this.target_id = "none";
    this.domain = "";
    this.server_ip = "";
  }
}
