import { Dropdown, Space } from "antd";
import { getDropdownOptions } from ".";

export const render_tags = (value: string[]) => {
    if (value?.length > 1) {
        let items = getDropdownOptions(value.filter((item, index) => { return index !== 0 }));
        return (<Space size="middle">
            <Space><>{value[0]}</><Dropdown menu={{ items }}><span >...</span></Dropdown></Space>
        </Space>)
    } else { return value?.toString() }
}