import {
  AppstoreAddOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import { MenuProps } from "antd";
import { editTargetList, mark_favorite, removeTarget } from "../actions";

export const getTargetContextItems = (record: any): MenuProps["items"] => [
  {
    key: "10",
    label: "Target: " + record.name,
    disabled: true,
  },
  {
    type: "divider",
  },
  {
    key: "1",
    label: (
      <span
        onClick={(e) => {
          e.preventDefault();
          window.open(`/ui/target/${record.id}/info`, "_blank");
        }}
      >
        <InfoCircleOutlined
          style={{
            marginRight: "7px",
            color: "rgb(74, 74, 245)",
            fontSize: "15px",
          }}
        />
        Open info page
      </span>
    ),
  },
  {
    key: "2",
    label: (
      <span
        onClick={(e) => {
          e.preventDefault();
          mark_favorite(record.id);
        }}
      >
        {record?.isFavorite ? (
          <StarFilled
            style={{ color: "#F4A460", marginRight: "7px", fontSize: "15px" }}
          />
        ) : (
          <StarOutlined
            style={{ color: "#F4A460", marginRight: "7px", fontSize: "15px" }}
          />
        )}

        {record?.isFavorite ? "Unmark favourite" : "Mark as favourite"}
      </span>
    ),
  },
  {
    key: "3",
    label: (
      <span
        onClick={(e) => {
          e.preventDefault();
          editTargetList(record);
        }}
      >
        <EditOutlined
          style={{ color: "green ", marginRight: "7px", fontSize: "15px" }}
        />
        Edit target
      </span>
    ),
  },
  {
    key: "4",
    label: (
      <span
        onClick={(e) => {
          e.preventDefault();
          editTargetList();
        }}
      >
        <AppstoreAddOutlined
          title="Add new target"
          style={{ color: "green", marginRight: "9px", fontSize: "15px" }}
        />
        Add new target
      </span>
    ),
  },
  {
    type: "divider",
  },
  {
    key: "5",
    label: (
      <span
        onClick={(e: any) => {
          e.preventDefault();
          removeTarget(record);
        }}
      >
        <DeleteOutlined
          style={{ color: "red", marginRight: "7px", fontSize: "15px" }}
        />
        Delete
      </span>
    ),
  },
];
