import { AxiosError } from "axios";
import { handleError, Targets } from "../shared";
import { Api } from ".";

export class SearchApi {
  getData = async () => {
    try {
      const response = await Api.get("/api/v1/target/list").then(
        (response: { data: any }) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  getTargetsBySearch = async (params: Targets) => {
    try {
      const response = await Api.post("/api/v1/session/list", params).then(
        (response: { data: any }) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  removeTargetByID = async (params: any) => {
    try {
      const response = await Api.remove(`/api/v1/target/${params}`).then(
        (response: { data: any }) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  addNewTarget = async (params: any) => {
    try {
      const response = await Api.post(`/api/v1/target`, { ...params }).then(
        (response: { data: any }) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  changeTargetById = async (id: string, params: any) => {
    try {
      const response = await Api.put(`/api/v1/target/${id}`, {
        ...params,
      }).then((response: { data: any }) => {
        return response.data;
      });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };
}
