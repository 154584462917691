import { useState } from "react";
import { Button, Divider, Form, Input, Modal, Popover, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useCredsState } from "../Store";
import { addTargetCreds, addHostCreds } from "../actions";

type Props = {
  targetId?: string;
  hostId?: string;
};

export type AddCredFormFields = {
  service: string;
  login: string;
  password: string;
  password_hash: string;
  key: string;
  comment: string;
};

export const AddCredModal = ({ targetId, hostId }: Props) => {
  const [form] = Form.useForm();
  const [state, actions] = useCredsState();
  const [isPopoverHidden, setPopoverHidden] = useState(true);

  const handleSubmit = (formData: AddCredFormFields): void => {
    if (!formData.password && !formData.password_hash && !formData.key) {
      setPopoverHidden(false);
      return;
    }
    targetId &&
      addTargetCreds(targetId, formData)
        .then(() => {
          message.success("Credentials added", 2.5);
          clear();
        })
        .catch((error) => {
          message.error(error.message, 2.5);
        });
    hostId &&
      addHostCreds(hostId, formData)
        .then(() => {
          message.success("Credentials added", 2.5);
          clear();
        })
        .catch((error) => {
          message.error(error.message, 2.5);
        });
  };

  const clear = async () => {
    await setPopoverHidden(true);
    actions.setAdding(false);
    form.resetFields();
  };

  return (
    <Modal
      width={450}
      className="addCredModal"
      title="Add credentials"
      open={state.isAdding}
      onOk={form.submit}
      onCancel={clear}
    >
      <Form
        layout="horizontal"
        name="AddCreds"
        form={form}
        onFinish={handleSubmit}
      >
        <Form.Item<AddCredFormFields> label="Service" name="service">
          <Input />
        </Form.Item>
        <Form.Item<AddCredFormFields> label="Login" name="login">
          <Input />
        </Form.Item>
        <Popover
          open={!isPopoverHidden}
          content={
            <>
              <span>Please fill one of the following fields </span>
              <Button
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => setPopoverHidden(true)}
              />
            </>
          }
        >
          <Divider style={!isPopoverHidden ? { borderColor: "red" } : {}} />
        </Popover>
        <Form.Item<AddCredFormFields> label="Password" name="password">
          <Input />
        </Form.Item>
        <Form.Item<AddCredFormFields>
          label="Password Hash"
          name="password_hash"
        >
          <Input />
        </Form.Item>
        <Form.Item<AddCredFormFields> label="Key" name="key">
          <Input />
        </Form.Item>
        <Divider style={!isPopoverHidden ? { borderColor: "red" } : {}} />
        <Form.Item<AddCredFormFields> label="Comment" name="comment">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
