import { ClientTableData, ServerToken, User, actionsType } from "../../../shared";
import { IAdminState, IUserState } from "./IAdminState";

export const actions = {
    setClients: (clients: ClientTableData[]) =>
        ({ setState, getState }: actionsType<IAdminState>) => setState({ ...getState(), clients }),

    setEditedClient: (editedClient: ClientTableData | null) =>
        ({ setState, getState }: actionsType<IAdminState>) => setState({ ...getState(), editedClient }),

    setIsClientEdit: (isClientEdit: boolean) =>
        ({ setState, getState }: actionsType<IAdminState>) => setState({ ...getState(), isClientEdit }),

    setIsUserEdit: (isUserEdit: boolean) =>
        ({ setState, getState }: actionsType<IAdminState>) => setState({ ...getState(), isUserEdit }),

    setUsers: (users: User[]) =>
        ({ setState, getState }: actionsType<IAdminState>) => setState({ ...getState(), users }),

    setEditedUser: (editedUser: User | undefined) =>
        ({ setState, getState }: actionsType<IAdminState>) => setState({ ...getState(), editedUser }),

    setShowTokens: (showTokens: boolean) =>
        ({ setState, getState }: actionsType<IAdminState>) => setState({ ...getState(), showTokens }),

    setServerTokens: (serverToken: ServerToken[]) =>
        ({ setState, getState }: actionsType<IAdminState>) => setState({ ...getState(), serverToken }),

    setCurrentUser: (current_user: IUserState) =>
        ({ setState, getState }: actionsType<IAdminState>) => setState({ ...getState(), current_user }),
};