import { DatePicker, Form, Input, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import Modal from "antd/es/modal/Modal";
import "../styles.css";
import { CmdApi } from "../../../Api";
import { CMDRequest } from "../../../shared/types/Cmd";
import { useTargetHostState } from "../Store";
import { COMMANDS_METHODS, getOptions } from "../../../shared";

const CMD_API = new CmdApi();

export const MultiCmdModal = () => {
  const [form] = Form.useForm(undefined);
  const [state, actions] = useTargetHostState();

  const addComand = () => {
    form.validateFields().then(
      async (result) => {
        await CMD_API.addCmdsToHosts({
          cmd: result.cmd,
          method: result.method ? result.method : "",
          target_host_ids: state.selectedRowKeys,
          scheduled_for: result.scheduled_for?.format(),
        } as CMDRequest);
        clear();
      },
      (error) => {
        console.error(error);
      }
    );
  };
  const clear = () => {
    actions.setMulti(false);
    form.resetFields();
  };

  return (
    <Modal
      width={"800px"}
      title="Add command to selected hosts"
      open={state.isMulti}
      okText="Submit"
      onOk={addComand}
      onCancel={clear}
    >
      <Form form={form} className="addcmd_form">
        <FormItem
          label="Comand"
          name={"cmd"}
          className="cmd_form_item"
          style={{ width: "100%", marginBottom: "20px" }}
          rules={[{ required: true }]}
        >
          <Input placeholder="Enter command" />
        </FormItem>
        <FormItem
          label="Default method"
          name={"method"}
          className="cmd_form_item"
          style={{ width: "96%", marginBottom: "20px" }}
        >
          <Select
            placeholder="Select method"
            options={getOptions(COMMANDS_METHODS)}
          />
        </FormItem>
        <FormItem
          label={"Schefuled For"}
          className="cmd_form_item"
          name="scheduled_for"
        >
          <DatePicker
            format="DD/MM/YYYY HH:mm"
            showTime={{ use12Hours: false }}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};
