import { Form, Input, Modal, Select, Table } from "antd";
import { useEffect, useState } from "react";
import { UserTokensApi } from "../../../Api";
import { getOptions, User } from "../../../shared";
import { getEditTokenCols } from "../columns/editTokenColumns";
import { useAdminState } from "../Store";

type Props = {
  updateUser: (user: User) => Promise<void>;
  setNewUser?: (newUser: User) => Promise<void>;
  width: number;
  isOpen: boolean;
};

const TOKEN_API = new UserTokensApi();

const ManageUserModal: React.FC<Props> = ({
  updateUser,
  setNewUser,
  width,
  isOpen,
}) => {
  const [userform] = Form.useForm(undefined);
  const [tokens, setTokens] = useState();
  const [state, actions] = useAdminState();

  const setInitValues = () =>
    state.editedUser
      ? state.editedUser
      : state.showTokens
      ? state.current_user
      : { login: "", password: "", roles: ["collector"] };

  const getTokens = async () => {
    isOpen && setTokens(await TOKEN_API.getTokens());
  };

  const deleteToken = async (id: string) => {
    await TOKEN_API.deleteTokenByID(id);
    getTokens();
  };

  const submitUpdate = () => {
    if (state.editedUser || state.showTokens) {
      updateUser({
        id: state.editedUser ? state.editedUser.id : state.current_user.id,
        ...userform.getFieldsValue(),
      });
      clear();
    } else {
      userform.validateFields().then((record) => {
        setNewUser && setNewUser(record);
        clear();
      });
    }
  };

  const switchToken = async (id: string) => {
    await TOKEN_API.switchToken(id);
    getTokens();
  };

  const clear = () => {
    actions.setIsUserEdit(false);
    actions.setShowTokens(false);
    userform.resetFields();
  };

  useEffect(() => {
    if (state.isUserEdit || state.showTokens) {
      userform.setFieldsValue(setInitValues());
    } else {
      actions.setEditedUser(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isUserEdit, state.showTokens]);

  useEffect(() => {
    getTokens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.showTokens]);

  return (
    <Modal
      className="manageUserModal"
      width={width}
      title={
        state.editedUser || state.showTokens ? "Edit user" : "Add new user"
      }
      open={isOpen}
      okText="save"
      onCancel={clear}
      onOk={submitUpdate}
    >
      <Form form={userform} initialValues={setInitValues()}>
        <Form.Item name="login" rules={[{ required: true }]}>
          <Input allowClear placeholder="enter login" className="userLogin" />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true, min: 16 }]}>
          <Input.Password
            className="userLogin"
            allowClear={true}
            visibilityToggle={true}
            placeholder="enter new password"
          />
        </Form.Item>
        <Form.Item name="roles">
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            options={getOptions(state.current_user?.allowed_roles)}
          />
        </Form.Item>
      </Form>
      {state.showTokens && (
        <>
          <label className="Tablelabel">Tokens list</label>
          <Table
            rowKey="id"
            dataSource={tokens}
            columns={getEditTokenCols(deleteToken, switchToken)}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "20"],
            }}
          />{" "}
        </>
      )}
    </Modal>
  );
};

export default ManageUserModal;
