import { useState } from "react";
import { Button, Tooltip, Upload } from "antd";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { useTableState } from "../store";
import { DATA_SERVICE } from "../Table";

type Props = {
  setData: () => Promise<void>;
  isHost?: boolean;
};

export const FileActionsBtns = ({ setData, isHost }: Props) => {
  const [state, actions] = useTableState();
  const [fileList, setFileList] = useState([]);

  const uploadFile = async (componentsData: any) => {
    let formData = new FormData();
    formData.set("collection_result", componentsData.file);
    await DATA_SERVICE.uploadFile(formData);
    componentsData.onSuccess();
    setData();
  };

  const uploadProps = {
    accept: ".gz",
    multipart: true,
    multiple: true,
    fileList: fileList,
    onChange: (info: any) => {
      setFileList(info.fileList.filter((f: any) => f.status !== "done"));
      fileList.length === 0 && actions.setRemoved(true);
    },
    customRequest: uploadFile,
  };

  const loadSelectedFiles = async () => {
    if (!state.isLoading) {
      actions.setLoading(!state.isLoading);
      await DATA_SERVICE.loadSelectedFiles(state.selectedRowKeys).then(() =>
        actions.setLoading(false)
      );
    }
    actions.setLoading(false);
    actions.setSelectedRowKeys([]);
  };

  return (
    <div className="files_actions" style={{ top: isHost ? "-50px" : "" }}>
      <Tooltip title={"Download selected files"} color="green">
        <Button
          disabled={state.selectedRowKeys.length < 1}
          className="actionBtn"
          icon={
            <DownloadOutlined style={{ color: "green", fontSize: "22px" }} />
          }
          onClick={loadSelectedFiles}
        >
          Download files
        </Button>
      </Tooltip>
      <Upload {...uploadProps}>
        <Button
          className="actionBtn"
          icon={
            <UploadOutlined style={{ color: "#cf8d99", fontSize: "22px" }} />
          }
        >
          Upload file
        </Button>
      </Upload>
    </div>
  );
};
