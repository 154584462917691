import React, { useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

import { MenuUnfoldOutlined } from "@ant-design/icons";
import { FloatButton, Tooltip } from "antd";
import Table from "antd/es/table";

import { Host, getCookie, pagination_options, setCookie } from "../../shared";
import {
  ColumnSelector,
  SearchTargets,
  TableSettings,
  TargetContext,
  Toggle,
  get_rowSelection,
} from "../shared";
import { useTargetHostState } from "./Store";
import "./styles.css";
import { MultiCmdModal, Get_target_columns } from "./ui";
import DeleteMultiBtn from "../shared/DeleteMultiBtn/DeleteMultiBtn";
import { getRowClass } from "./actions";
import ChangeAlertModal from "./HostInfo/ui/ChangeAlertModal";

const TargetHostPage: React.FC<{
  hostInfo?: Host[];
  setIsRerender?: (isRerender: boolean) => void;
}> = ({ hostInfo, setIsRerender }) => {
  const navi = useNavigate();
  const token = getCookie("token") as string;
  const { targetsList } = React.useContext(TargetContext)!;
  const [state, actions] = useTargetHostState();

  const handleChange = (sorter: any) => {
    setCookie("hosts_sorter", `${sorter.columnKey},${sorter.order}`, 365);
    actions.changeSelectedColumnsSorting(sorter);
  };

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (state.isActive && !state.isLoading && !hostInfo) {
      interval = setInterval(() => {
        actions.setTableData();
      }, 10 * 1000);
      return () => {
        clearInterval(interval);
      };
    } else {
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isActive, state.isLoading]);

  useLayoutEffect(() => {
    if (!hostInfo) {
      actions.setTableData();
    }
    actions.setSelectedRowKeys([]);
    document.title = "Hosts / BartUI";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostInfo]);

  return !token ? (
    <></>
  ) : (
    <div
      className={hostInfo ? "" : "messages_table"}
      style={{ position: "relative" }}
    >
      {!hostInfo ? (
        <>
          <SearchTargets
            getData={actions.setTableData}
            tableData={state.tabledata}
            type={"target"}
            typesList={state.typesList}
            setMulti={
              state.selectedRowKeys.length > 0 ? actions.setMulti : undefined
            }
          />
          <TableSettings>
            <Toggle
              active={state.isActive}
              onClick={actions.setTableData}
              loading={state.isLoading}
              setActive={actions.setActive}
            />
            <ColumnSelector
              tabledata={state.tabledata}
              targetsList={targetsList}
              columns={Get_target_columns(targetsList)}
              selected_values={
                state.selectedColumns?.map((item) =>
                  item.title?.toString()
                ) as string[]
              }
              cookie_name="hosts"
              setSelectedColumns={actions.setSelectedColumns}
            />
          </TableSettings>
        </>
      ) : (
        state.selectedRowKeys.length > 0 && (
          <>
            <Tooltip title={"Add commands"}>
              <MenuUnfoldOutlined
                className={"showMulti_host"}
                onClick={() => {
                  actions.setMulti(true);
                }}
              />
            </Tooltip>
            <DeleteMultiBtn
              className="multi_del_btn"
              getData={() => {
                setIsRerender ? setIsRerender(true) : actions.setTableData();
              }}
            />
          </>
        )
      )}
      <Table
        scroll={{ x: 1500 }}
        className="netCatTable"
        columns={
          hostInfo
            ? Get_target_columns(undefined, hostInfo).columns
            : state.selectedColumns
        }
        dataSource={hostInfo ? hostInfo : state.tabledata}
        pagination={
          hostInfo && hostInfo?.length < 50 ? false : pagination_options
        }
        rowKey="id"
        rowClassName={(record) =>
          getRowClass(record.last_active_at) ? "not_active_host" : ""
        }
        onChange={(p, f, sorter, e) => {
          if (f) {
            actions.setFilters(f);
          }
          handleChange(sorter);
        }}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              navi(`/ui/target-host/${record.id}/info`);
            },
          };
        }}
        rowSelection={get_rowSelection(state, actions)}
      />
      <MultiCmdModal />
      {state.alert_id && <ChangeAlertModal id={state.alert_id} />}
      <FloatButton.BackTop />
    </div>
  );
};

export default TargetHostPage;
