import { BeaconApi } from "../../../../Api";
import {
  AutocompleteOption,
  BeaconMessage,
  actionsType,
} from "../../../../shared";
import { getSessions, processMessages } from "../actions";
import { Sessions } from "../ui/BeaconSessions";
import { IMessagesState } from "./IMessagesState";

const BEACON_CLIENT = new BeaconApi();

export const actions = {
  setOptions:
    (options: AutocompleteOption[]) =>
    ({ setState, getState }: actionsType<IMessagesState>) =>
      setState({ ...getState(), options }),

  setCommands:
    (commands: string[]) =>
    ({ setState, getState }: actionsType<IMessagesState>) =>
      setState({ ...getState(), commands }),

  addComand:
    (command: string) =>
    ({ setState, getState }: actionsType<IMessagesState>) => {
      const commands = [...getState().commands, command];
      setState({ ...getState(), commands });
    },

  setMessages:
    (messages: BeaconMessage[]) =>
    ({ setState, getState }: actionsType<IMessagesState>) =>
      setState({ ...getState(), messages }),

  setMsgsInProgress:
    (msgsInProgress: BeaconMessage[]) =>
    ({ setState, getState }: actionsType<IMessagesState>) =>
      setState({ ...getState(), msgsInProgress }),

  setSessions:
    (sessions: Sessions) =>
    ({ setState, getState }: actionsType<IMessagesState>) =>
      setState({ ...getState(), sessions }),

  setBeacon:
    (id: string) =>
    async ({ setState, getState }: actionsType<IMessagesState>) => {
      let beacon = await BEACON_CLIENT.getBeaconById(`${id}`);
      let response = await BEACON_CLIENT.getMessages(`${id}`);

      let msgsInProgress = response?.filter((i: any) => !i.processed_at);

      getSessions(beacon?.target_host?.target_host_id);
      processMessages(
        response,
        getState().options.map((i) => i.value)
      );
      setState({ ...getState(), beacon, msgsInProgress });
    },
};
