import {
  Edges,
  NodeInfo,
  Nodes,
  SigmaState,
  actionsType,
} from "../../../../../shared";
import { IMapState } from "./IMapState";

export const actions = {
  setGraphData:
    (graphData: { nodes: Nodes[]; edges: Edges[] }) =>
    ({ setState, getState }: actionsType<IMapState>) =>
      setState({ ...getState(), graphData }),

  setGraphState:
    (graphState: SigmaState) =>
    ({ setState, getState }: actionsType<IMapState>) =>
      setState({ ...getState(), graphState }),

  setRoutesState:
    (routes: { nodes: string[]; edges: string[] }) =>
    ({ setState, getState }: actionsType<IMapState>) =>
      setState({ ...getState(), routes }),

  setNodeInfo:
    (nodeInfo: NodeInfo | undefined) =>
    ({ setState, getState }: actionsType<IMapState>) =>
      setState({ ...getState(), nodeInfo }),

  setIsLoading:
    (isLoading: boolean) =>
    ({ setState, getState }: actionsType<IMapState>) =>
      setState({ ...getState(), isLoading }),

  setIsDrawerOpen:
    (isDrawerOpen: boolean) =>
    ({ setState, getState }: actionsType<IMapState>) =>
      setState({ ...getState(), isDrawerOpen }),

  setIsRoutes:
    (isRoutes: boolean) =>
    ({ setState, getState }: actionsType<IMapState>) =>
      setState({ ...getState(), isRoutes }),

  setIsGateways:
    (isGateways: boolean) =>
    ({ setState, getState }: actionsType<IMapState>) =>
      setState({ ...getState(), isGateways }),
  setIEdges:
    (i_edges: boolean) =>
    ({ setState, getState }: actionsType<IMapState>) =>
      setState({ ...getState(), i_edges }),

  setIsProcesses:
    (processes: boolean) =>
    ({ setState, getState }: actionsType<IMapState>) =>
      setState({ ...getState(), processes }),
};
