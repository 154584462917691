import {
  EditOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Modal } from "antd";
import { SearchApi } from "../../../Api";
import { TargetModel } from "../../../shared";
import { TargetStore } from "../Store";
import { DEFAULT_EDGE_CURVATURE } from "@sigma/edge-curve";

import cls from "../targets.module.css";

const SEARCH_CLIENT = new SearchApi();

export const removeTarget = async (record: any) => {
  Modal.confirm({
    icon: <UsergroupDeleteOutlined />,
    title: (
      <span>
        Are you sure you want to delete the{" "}
        <b style={{ color: "red" }}>{record.name.toUpperCase()}</b> target? All
        related entities will be deleted
      </span>
    ),
    okText: "Delete",
    okType: "danger",
    onOk: async () => {
      await SEARCH_CLIENT.removeTargetByID(record.id);
      TargetStore.actions.setTableData();
    },
  });
};

export const parseFavorites = (tableData: TargetModel[]): TargetModel[] => {
  return tableData
    ?.map((item) => {
      item.isFavorite = TargetStore.storeState
        .getState()
        .favorites.includes(item.id);
      return item;
    })
    .sort((a, b) => a.name?.localeCompare(b.name))
    .sort((a, b) => (a.has_alerts === b.has_alerts ? 0 : a.has_alerts ? -1 : 1))
    .sort((a, b) => {
      return a.isFavorite === b.isFavorite ? 0 : a.isFavorite ? -1 : 1;
    });
};

export const editTargetList = async (record?: any) => {
  const init = {
    name: record?.name,
    server_ip: record?.server_ip,
    domain: record?.domain,
  };
  let modal = Modal.confirm({
    icon: record?.id ? <EditOutlined /> : <UsergroupAddOutlined />,
    title: record?.id ? "Edit target " : "Add new target ",
    content: (
      <Form
        onFinish={(values) => {
          manageTargetNames(values, record?.id);
          modal.destroy();
        }}
        className={cls.target_form}
        initialValues={init}
      >
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="server_ip" label="Server ip">
          <Input />
        </Form.Item>
        <Form.Item name="domain" label="Domain">
          <Input />
        </Form.Item>
        <Form.Item style={{ float: "right" }}>
          <Button
            htmlType="button"
            onClick={() => {
              modal.destroy();
            }}
          >
            Cancel
          </Button>
          <Button
            style={{ marginLeft: "20px" }}
            type="primary"
            htmlType="submit"
          >
            OK
          </Button>
        </Form.Item>
      </Form>
    ),
    closable: true,
    width: 550,
    footer: <></>,
  });
};

const manageTargetNames = async (values: any, id?: string) => {
  if (values.name && values.name.length > 255) {
    let modal = Modal.error({
      title: "The length cannot exceed 255 characters",
    });
    setTimeout(modal.destroy, 2 * 1000);
    return;
  }
  id
    ? await SEARCH_CLIENT.changeTargetById(id, values)
    : await SEARCH_CLIENT.addNewTarget(values);
  TargetStore.actions.setTableData();
};

export const getCurvature = (index: number, maxIndex: number): number => {
  if (maxIndex <= 0) throw new Error("Invalid maxIndex");
  if (index < 0) return -getCurvature(-index, maxIndex);
  const amplitude = 3.5;
  const maxCurvature =
    amplitude * (1 - Math.exp(-maxIndex / amplitude)) * DEFAULT_EDGE_CURVATURE;
  return (maxCurvature * index) / maxIndex;
};
