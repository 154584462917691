import { createHook, createStore, defaultRegistry } from "react-sweet-state";
import { actions, IMenuState } from ".";


export const initialState: IMenuState = {
  theme: 'light',
  key: '',
  selectedKeys: []
};

export const Store = createStore<IMenuState, typeof actions>({
  initialState,
  actions
});

export const useMenuState = createHook(Store);

export const MenuStore = defaultRegistry.getStore(Store);