import { createHook, createStore } from "react-sweet-state";
import { IMapState } from "./IMapState";
import { actions } from "./Actions";

const initialState: IMapState = {
  graphData: { nodes: [], edges: [] },
  graphState: { searchQuery: "", searchRoutesQuery: "" },
  routes: { nodes: [], edges: [] },
  nodeInfo: undefined,
  isLoading: false,
  isDrawerOpen: false,
  isRoutes: true,
  isGateways: false,
  i_edges: true,
  processes: true,
};

export const Store = createStore<IMapState, typeof actions>({
  initialState,
  actions,
});

export const useMapState = createHook(Store);
