import { createHook, createStore, defaultRegistry } from 'react-sweet-state';
import { IMessagesState } from './IMessagesState';
import { actions } from './Actions';

const initialState: IMessagesState = {
  options: [],
  commands: [],
  messages: [],
  msgsInProgress: [],
  sessions: {},
  beacon: undefined
};

export const Store = createStore<IMessagesState, typeof actions>({
  initialState,
  actions
});

export const useMessagesState = createHook(Store);

export const MsgStore = defaultRegistry?.getStore(Store);
