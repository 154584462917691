import { createHook, createStore, defaultRegistry } from "react-sweet-state";
import { IBeaconState } from "./IBeaconState";
import { actions } from "./Actions";

const initialState: IBeaconState = {
  isActive: false,
  isLoading: false,
  isMulti: false,
  tabledata: [],
  filteredData: [],
  filteredInfo: undefined,
  typesList: [],
  selectedColumns: [],
  selectedRowKeys: [],
  checkSwith: false,
};

export const Store = createStore<IBeaconState, typeof actions>({
  initialState,
  actions,
});

export const useBeaconState = createHook(Store);

export const BeaconStore = defaultRegistry.getStore(Store);
