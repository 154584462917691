import { CloseCircleOutlined, CopyOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import {
    BeaconMessage, DateCorrector, HostInfo,
    NetCatResponseData, copyToClipboard, onlyUnique
} from "../../../../shared";
import { MsgStore } from "../Store";
import { TargetApi } from "../../../../Api";
import { BeaconSession, Sessions } from "../ui";

const TARGET_CLIENT = new TargetApi();

const SESSION_TYPES = ['revshell', 'proxy'];

export const renderCopyButton = (connectionString: string, isDisabled: boolean): JSX.Element => {
    return isDisabled ? <CopyOutlined style={{ fontSize: '19px', color: 'grey' }} /> :
        <Tooltip title="Copy connection string" color="green">
            <CopyOutlined id="Copy" title="Copy connection string" style={{ color: "green", fontSize: "19px" }}
                onClick={(event) => copyToClipboard("Copy", connectionString, 'cpd', event.clientY + 150, '-50%')} />
        </Tooltip>;
}


export const renderDate = (lastSeen: string): JSX.Element => <DateCorrector date={new Date(lastSeen)} />;

export const renderStopButton = (
    stopID: string,
    handleStopSession: (value: any) => void,
    type: 'revshell' | 'proxy',
    isDisabled: boolean): JSX.Element => {
    return isDisabled ? <CloseCircleOutlined style={{ fontSize: '19px', color: 'grey' }} /> :
        <Tooltip color="red" title="Stop target session">
            <CloseCircleOutlined style={{ fontSize: '19px' }}
                onClick={(event) => {
                    type === 'revshell' && handleStopSession('stop-revsh-host');
                    type === 'proxy' && handleStopSession('stop-rev-proxy');
                }} />
        </Tooltip>;
}


export const processMessages = (messages: BeaconMessage[], options?: string[]): void => {
    let optionsList: string[] = options ? options : [];
    if (messages) {
        messages.map((msg: BeaconMessage, i: number, messages: BeaconMessage[]) => {
            msg = { ...msg, count: i + 1 };
            const { request } = msg;
            optionsList.push(request);
            return msg;
        });
        MsgStore.actions.setMessages(messages);
    } 
    MsgStore.actions.setOptions(optionsList.filter(onlyUnique).map(option => ({ value: option })));
}


export const getSessions = (hostId: string | undefined): void => {
    hostId && TARGET_CLIENT.getHostDetails(hostId).then((host: HostInfo) => {
        const sessions: Sessions = {};
        SESSION_TYPES.forEach(sessionType => {
            const session: NetCatResponseData | undefined = host?.remote_sessions?.find(session => session.type === sessionType);

            if (session) {
                sessions[sessionType as keyof Sessions] = new BeaconSession(session);
            }
        });
        MsgStore.actions.setSessions(sessions);
    });
}

export const getSessionsWrapper = (command: string) => {
    if (command.indexOf('revsh') !== -1 || command.indexOf('proxy') !== -1) {
        getSessions(MsgStore.storeState.getState().beacon?.target_host.target_host_id);
    }
}




