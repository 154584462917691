import { createHook, createStore, defaultRegistry } from 'react-sweet-state';
import { actions } from './Actions';
import { ICredsState } from './ICredsState';
import { CredentialModel } from '../../../shared';

const initialState: ICredsState = {
    tabledata: [],
    isAdding: false,
    isEditing: false,
    edited: new CredentialModel(),
    currentPage: 1,
    sources: [],
    selectedColumns: [],
    selectedRowKeys: [],
};

export const Store = createStore<ICredsState, typeof actions>({
    initialState,
    actions
});

export const useCredsState = createHook(Store);

export const CredsStore = defaultRegistry.getStore(Store);