import { DeleteOutlined } from "@ant-design/icons";
import { removeConnection } from "../actions";
import { Rollback } from "../../../shared";

export const HeaderBtns = () => {
  return (
    <div className="info_header_btns">
      <DeleteOutlined
        title="Delete host"
        className="del_btn"
        onClick={removeConnection}
      />
      <Rollback title="back to hosts list" delta={"/ui/target-host"} />
    </div>
  );
};
