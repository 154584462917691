import { Tooltip } from "antd";
import { HostInfoStore } from "../HostInfo/store";
import { HostStore } from "../Store";

export type AlertLevel = "red" | "green" | "yellow" | undefined;

export const host_alert_level = (alert: AlertLevel, id: string) => {
  return (
    <>
      <Tooltip
        color={alert !== undefined ? alert : "grey"}
        title={`${alert} alert level`}
      >
        <div
          className="round"
          onClick={() => {
            HostStore.actions.setAlertId(id);
            HostInfoStore.actions.setChangeAlert(true);
          }}
          style={{
            backgroundColor: alert !== undefined ? alert : "grey",
            cursor: "pointer",
          }}
        ></div>
      </Tooltip>
    </>
  );
};
