import { FloatButton, Form, Table } from "antd";
import { ExpandableConfig } from "antd/es/table/interface";
import { useEffect } from "react";
import { TableDataApi } from "../../Api";
import {
  Tabledata,
  Targets,
  collection_result_analysis,
  getCookie,
  pagination_options,
} from "../../shared";
import { useTableState } from "./store";
import "./styles.css";
import { FileActionsBtns, Table_columns } from "./ui";
import { get_rowSelection, SearchTargets } from "../shared";
import { expandedRowRender } from "../TargetHosts/HostInfo/ui";
import React from "react";
import AuthLocalStorage from "../../AuthLocalStorage";
import DateForm from "../Statistics/ui/DateForm";
import { analysisMap } from "../TargetHosts/HostInfo/actions";

type Props = {
  collection_results?: Tabledata[];
  setIsRerender?: (isRerender: boolean) => void;
  collection_result_analysis?: collection_result_analysis[];
  colsType?: string;
  id?: string;
};
export const DATA_SERVICE = new TableDataApi();

const DataTable: React.FC<Props> = ({
  collection_results,
  setIsRerender,
  collection_result_analysis,
  colsType,
  id,
}) => {
  const token = getCookie("token") as string;
  const [state, actions] = useTableState();
  const [dateForm] = Form.useForm();

  const setData = async () => {
    if (!collection_results) {
      let target = AuthLocalStorage.getCollectionsSelects()
        ? JSON.parse(AuthLocalStorage.getCollectionsSelects()!)
        : new Targets();
      if (id) {
        target.target_id = id;
      }
      dateForm.validateFields().then(
        async (result) => {
          target.since = new Date(result.since?.format()).toISOString();
          target.until = new Date(result.until?.format()).toISOString();
          let data = await DATA_SERVICE.getData(target);
          actions.setTableData(data?.collection_results);
          actions.setCollectionsAnalysis(
            analysisMap(data?.collection_result_analysis)
          );
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      actions.setTableData(collection_results);
      actions.setCollectionsAnalysis(
        collection_result_analysis ? collection_result_analysis : []
      );
    }
  };

  const expandable = {
    expandedRowRender: (record: any) =>
      expandedRowRender(
        state.collection_analysis?.find(
          (i) => record.id === i.collection_result_id
        )
      ),
    rowExpandable: (record: { id: string }) => {
      let cols = state.collection_analysis?.filter(
        (i) => record.id === i.collection_result_id
      )[0];

      return (
        cols &&
        (cols.keys?.length > 0 ||
          cols.miscellaneous_files?.length > 0 ||
          (cols.processes?.length > 0 &&
            cols.processes?.filter((i) => i.is_running)?.length > 0))
      );
    },
  } as ExpandableConfig<Tabledata>;

  useEffect(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection_results, id]);

  useEffect(() => {
    if ((collection_results || id) && state.isRemoved) {
      setIsRerender!(state.isRemoved);
    } else if (state.isRemoved) {
      setData();
    }
    actions.setRemoved(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isRemoved]);

  useEffect(() => {
    if (!collection_results && !id) {
      document.title = "Collector / BartUI";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !token ? (
    <></>
  ) : (
    <div
      className={collection_results || id ? "" : "messages_table"}
      style={{ position: "relative" }}
    >
      {collection_results || id ? null : (
        <SearchTargets
          getData={setData}
          tableData={state.tabledata}
          type={"collections"}
          typesList={[]}
          setMulti={undefined}
        />
      )}
      <div className="top_collect_sections">
        {collection_results ? null : (
          <DateForm form={dateForm} loadData={setData} type="collections" />
        )}
        <FileActionsBtns
          setData={setData}
          isHost={collection_results && collection_results?.length > 0}
        />
      </div>
      <Table
        className="collection_result"
        columns={Table_columns(state.tabledata, colsType)}
        rowSelection={get_rowSelection(state, actions)}
        expandable={expandable}
        dataSource={state.tabledata}
        pagination={collection_results || id ? false : pagination_options}
        rowKey="id"
      />
      <FloatButton.BackTop />
    </div>
  );
};
export default DataTable;
