import { ColumnsType } from "antd/es/table";
import {
  CredentialModel,
  SearchTargetsModel,
  date_sorter,
  getCookie,
  getFilterOptions,
  onlyUnique,
  sortOptions,
  sorter,
} from "../../../shared";

import {
  renderAction,
  renderCopiedData,
  renderDate,
  render_file_link,
  render_host_link,
  render_target_link,
} from "../actions";
import { NavigateFunction } from "react-router-dom";

export const GetcredsColunms = (
  tabledata: CredentialModel[],
  targetsList: SearchTargetsModel[],
  fn: () => void,
  colsType?: string,
  navi?: NavigateFunction
) => {
  const roles = getCookie("roles")?.split(",");

  const columns = (
    [
      {
        title: "Login",
        dataIndex: "login",
        key: "login",
        filters: sortOptions(
          getFilterOptions(
            tabledata
              ?.map((x) =>
                x.login?.length > 20 ? x.login.substring(0, 20) : x.login
              )
              ?.filter(onlyUnique)
          )
        ),
        filterSearch: true,
        onFilter: (value: any, record: any) => record.login?.includes(value),
        sorter: (a: any, b: any) => sorter(a.login, b.login),
        width: "10%",
      },
      {
        title: "Password",
        dataIndex: "password",
        key: "password",
        width: "10%",
        ellipsis: true,
        filters:
          colsType === "targetInfo" && getFilterOptions(["empty", "not empty"]),
        filterSearch: true,
        onFilter: (value: any, record: any) => {
          return value === "empty"
            ? record.password?.length === 0
            : record.password.length > 0;
        },
        render: (_text: any, record: any) =>
          renderCopiedData(record.id, record.password, 310),
      },
      {
        title: "Password hash",
        dataIndex: "password_hash",
        key: "password_hash",
        width: "10%",
        ellipsis: true,
        render: (_text: any, record: any) =>
          renderCopiedData(record.id, record.password_hash, 310),
      },
      {
        title: "Key",
        dataIndex: "key",
        key: "key",
        width: "10%",
        ellipsis: true,
        filters:
          colsType === "targetInfo" && getFilterOptions(["empty", "not empty"]),
        filterSearch: true,
        onFilter: (value: any, record: any) => {
          return value === "empty"
            ? record.key?.length === 0
            : record.key.length > 0;
        },
        render: render_file_link,
      },
      colsType === undefined || colsType === "hostInfo"
        ? {
            title: "Target",
            dataIndex: "target",
            key: "target",
            render: (value: any, record: any) =>
              render_target_link(value, record, targetsList, navi!),
            width: "10%",
          }
        : null,
      colsType !== "hostInfo"
        ? {
            title: "Target host",
            dataIndex: "target_host",
            key: "target_host",
            width: "10%",
            render: (value: any, record: any) =>
              render_host_link(value, record, navi!),
          }
        : null,
      {
        title: "Source",
        dataIndex: "source",
        key: "source",
        width: "10%",
        filters:
          colsType === "targetInfo" &&
          sortOptions(
            getFilterOptions(
              tabledata?.map((x) => x.source)?.filter(onlyUnique)
            )
          ),
        filterSearch: true,
        onFilter: (value: any, record: any) => record?.source?.includes(value),
      },
      {
        title: "Service",
        dataIndex: "service",
        key: "service",
        filters: getFilterOptions(
          tabledata
            ?.map((item: any) => (item?.service ? item?.service : ""))
            ?.filter(onlyUnique)
        ),
        filterSearch: true,
        onFilter: (value: any, record: any) => record?.service?.includes(value),
        width: "10%",
      },
      roles?.includes("all-sessions")
        ? {
            title: "Client",
            dataIndex: "client",
            key: "client",
            width: "7%",
            filters: getFilterOptions(
              tabledata
                ?.map((item: any) => (item?.client ? item?.client : ""))
                ?.filter(onlyUnique)
            ),
            filterSearch: true,
            onFilter: (value: any, record: any) =>
              record?.client?.includes(value),
          }
        : null,
      {
        title: "Created at",
        dataIndex: "created_at",
        key: "created_at",
        width: "12%",
        render: (text: any, record: any) => renderDate(record.created_at),
        sorter: (a: { created_at: any }, b: { created_at: any }) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      },
      {
        title: "Last used",
        dataIndex: "last_used",
        key: "last_used",
        width: "12%",
        render: (text: any, record: any) =>
          record.last_used ? renderDate(record?.last_used) : <></>,
        sorter: (a: any, b: any) => date_sorter(a.last_used, b.last_used),
      },
      {
        title: "CMD",
        dataIndex: "cmd",
        key: "cmd",
        width: "10%",
        ellipsis: true,
        render: (_text: any, record: any) =>
          renderCopiedData(record.id, record.cmd, 320, 20),
      },
      {
        title: "Comment",
        dataIndex: "comment",
        key: "comment",
        width: "16%",
        ellipsis: true,
      },
      {
        title: "Actions",
        dataIndex: "comment",
        key: "comment",
        width: 150,
        fixed: "right",
        render: (text: any, record: any) => renderAction(record, fn),
      },
    ] as ColumnsType<any>
  ).filter((i) => i !== null);

  return {
    columns: columns,
    column_names: columns.map((item) => item.title?.toString()) as string[],
  };
};
