import { DeleteOutlined, EditFilled, RollbackOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import cls from '../notes.module.css';
import { useNavigate } from 'react-router-dom';

type Props = {
    setIsEdit: React.Dispatch<React.SetStateAction<boolean>>,
    deleteNote: () => void
}

const EditNoteBtn: React.FC<Props> = ({ setIsEdit, deleteNote }) => {
    const navi = useNavigate()
    return (
        <>
            <Tooltip title="Delete note" color='red'>
                <DeleteOutlined onClick={() => { deleteNote() }} className={cls.deleteBtn} />
            </Tooltip>
            <Tooltip title='back' color='#cd9423'>
                <RollbackOutlined className={cls.deleteBtn}
                    style={{ color: "#cd9423" }} onClick={() => navi(-1)} />
            </Tooltip>
            <Tooltip title="Edit note" color='lightblue'>
                <EditFilled style={{ color: 'lightblue' }}
                    className={cls.deleteBtn}
                    onClick={() => { setIsEdit(true) }} />
            </Tooltip>
        </>
    )
}

export default EditNoteBtn