import { Table } from "antd";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";


const defaultExpandable = {
    expandedRowRender: (record: any) =>
        <TextArea className='log_text' readOnly autoSize
            value={JSON.stringify(record, undefined, 4)}
        />,
};


export const Logs_table = (text: any[]) => {
    text?.sort((x, y) => {
        return y._timestamp - x._timestamp;
    })

    let colls = [{
        title: 'Time',
        dataIndex: '_timestamp',
        key: '_timestamp',
        render: (text: any, record: any) => moment(record._timestamp / 1000).format('MMM DD YYYY, HH:mm')
    },
    {
        title: 'User',
        dataIndex: 'user_name',
    },
    {
        title: 'Message',
        dataIndex: 'message',
    }]

    return < Table
        expandable={defaultExpandable}
        columns={colls}
        dataSource={text}
        rowKey='_timestamp'
        pagination={false} />
}