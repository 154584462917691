import { Modal } from "antd";
import { BeaconStore } from "../Store";
import { Beacon } from "../../../shared";
import TextArea from "antd/es/input/TextArea";
import { CommentOutlined } from "@ant-design/icons";
import { BeaconApi } from "../../../Api";

const BEACON_CLIENT = new BeaconApi();

export const addComment = async (
  record: any,
  setIsRerender?: (isRerender: boolean) => void
) => {
  Modal.confirm({
    icon: <CommentOutlined />,
    className: "add_server_modal",
    title: `Add comment for beacon - host: ${record.target_host?.hostname}, target: ${record.target_host?.target}`,
    content: (
      <TextArea
        rows={8}
        className="coment_area"
        id="beacon_comment"
        defaultValue={record.comment}
      />
    ),
    width: 550,
    okText: "Save",
    onCancel: () => {},
    onOk: () => _sendComment(record, setIsRerender),
  });
};

const _sendComment = async (
  record: any,
  setIsRerender?: (isRerender: boolean) => void
) => {
  let newValue = (
    document.getElementById("beacon_comment") as HTMLTextAreaElement
  )?.value;
  await BEACON_CLIENT.update({
    id: record.id,
    commentText: newValue,
    target_id: record.target_id,
  });
  setIsRerender ? setIsRerender(true) : BeaconStore.actions.setTableData();
};

export const updateRecord = async (
  record: Beacon
) => {
  BeaconStore.actions.setActive(false);
  Modal.confirm({
    title: `Do you want to update this beacon?  `,
    content: (
      <>
        Host name: {record.hostname} <br></br> Client IP: {record.client_ip}
      </>
    ),
    okText: "Update",
    okType: "danger",
    onOk: async () => {
      await BEACON_CLIENT.updateBin(record.id);
      BeaconStore.actions.setActive(true);
    },
    onCancel: () => BeaconStore.actions.setActive(true),
  });
};

export const deleteRecord = async (
  record: Beacon,
  setIsRerender?: (isRerender: boolean) => void
) => {
  BeaconStore.actions.setActive(false);
  Modal.confirm({
    title: `Do you want to delete this item?  `,
    content: (
      <>
        Host name: {record.hostname} <br></br> Client IP: {record.client_ip}
      </>
    ),
    okText: "delete",
    okType: "danger",
    onOk: async () => {
      await BEACON_CLIENT.delete(record.id);
      setIsRerender ? setIsRerender!(true) : BeaconStore.actions.setTableData();
      BeaconStore.actions.setActive(true);
    },
    onCancel: () => BeaconStore.actions.setActive(true),
  });
};

export const showInfoModal = (record: Beacon) => {
  Modal.info({
    title: "Info",
    className: "manageUserModal",
    maskClosable: true,
    okText: "Close",
    content: (
      <div style={{ fontWeight: "bold" }}>
        <p>Hostname: {record?.target_host.hostname} </p>
        <p>
          Client Ips:{" "}
          <textarea
            disabled
            className="info_text"
            defaultValue={record?.target_host.internal_ips?.replaceAll(
              ",",
              "\n"
            )}
          />{" "}
        </p>
        <p>OS: {record?.target_host.os} </p>
        <p>System id: {record?.target_host.machine_id}</p>
        <p>Username: {record?.target_host?.username} </p>
      </div>
    ),
    onOk() {},
    width: 760,
  });
};

export const deleteBeacons = async (
  getData: (data?: any) => Promise<void> | any
) => {
  Modal.confirm({
    title: `Do you want to delete multiple rows  `,
    okText: "Delete",
    okType: "danger",
    onOk: async () => {
      await deleteMultiRows(getData);
      BeaconStore.actions.setMulti(false);
    },
    onCancel: () => {},
  });
};

const deleteMultiRows = async (
  getData: (data?: any) => Promise<void> | any
) => {
  let keys = BeaconStore.storeState.getState().selectedRowKeys;
  keys.forEach(async (key) => {
    await BEACON_CLIENT.delete(key);
  });
  setTimeout(() => getData(), 500);
};
