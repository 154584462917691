import { Form, FormInstance, Input, Select } from "antd";
import React, { useEffect } from "react";
import cls from "../notes.module.css";

import { TargetNote } from "../../../shared";
import MDEditor from "@uiw/react-md-editor";
import { useTargetState } from "../../TargetsPage/Store";
import { TargetApi } from "../../../Api";

type Props = {
  form: FormInstance<any>;
  note?: TargetNote | undefined;
  setIsDirty: (isDirtyNote: boolean) => void;
  id?: string;
};

const TARGET_CLIENT = new TargetApi();

const AddNoteForm: React.FC<Props> = ({ form, note, setIsDirty, id }) => {
  const [state, actions] = useTargetState();

  const getHostList = async () => {
    let results = await TARGET_CLIENT.getTargetHostsList(
      `${id}`,
      state.isHostTable
    );
    results && actions.setHosts(results);
  };

  const getOptions = () => {
    return state.hosts?.map((item: any) => {
      return {
        value: item.id,
        label: `${item.hostname} / ${item.external_ip}`,
      };
    });
  };

  useEffect(() => {
    getHostList();
    if (note) {
      form.setFieldValue("title", note?.title);
      form.setFieldValue("content", note?.content);
      form.setFieldValue(
        "hosts",
        note?.hosts.map((item: any) => item.id)
      );
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note]);

  return (
    <Form style={{ width: "100%", height: "max-content" }} form={form}>
      <Form.Item name="title" style={{ margin: "auto", width: "85%" }}>
        <Input
          placeholder="Enter note title"
          style={{ marginBottom: "20px", width: "100%" }}
        />
      </Form.Item>
      <Form.Item name="hosts" style={{ margin: "auto", width: "85%" }}>
        <Select
          placeholder={"Select hosts"}
          style={{ marginBottom: "20px", width: "100%" }}
          mode="multiple"
          options={getOptions()}
          onChange={(value) => {
            setIsDirty(true);
          }}
        />
      </Form.Item>
      <Form.Item name="content" style={{ height: "60vh" }}>
        <MDEditor
          className={cls.textEditor}
          onChange={() => setIsDirty(true)}
          preview="edit"
        />
      </Form.Item>
    </Form>
  );
};

export default AddNoteForm;
