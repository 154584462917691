import { Flex, Tabs } from "antd";
import { useEffect, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { TargetApi } from "../../../Api";
import { getCookie } from "../../../shared";
import { useTargetState } from "../Store";
import { get_tabs } from "../ui";
import { getTargetDetails } from "../actions";
import { InfoHeader } from "../ui";
import { Rollback } from "../../shared";

const TARGET_CLIENT = new TargetApi();

const TargetInfoPage = () => {
  const [state, actions] = useTargetState();
  const token = getCookie("token") as string;
  const routeParams = useParams();

  const getHostList = async () => {
    let results = await TARGET_CLIENT.getTargetHostsList(
      `${routeParams.id}`,
      state.isHostTable
    );
    results && actions.setHosts(results);
  };

  useEffect(() => {
    if (state.isRerender) {
      getTargetDetails(routeParams.id, state.isRerender);
      actions.setIsRerender(false);
      getHostList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isRerender, state.targetDetails]);

  useLayoutEffect(() => {
    getTargetDetails(routeParams.id, state.isRerender);
    getHostList();
    routeParams.id !== state.targetDetails?.id && actions.setTab("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeParams.id]);

  useEffect(() => {
    state.tab.length === 0 && getHostList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tab, state.isHostTable]);

  useEffect(() => {
    document.title = state.targetDetails?.name
      ? state.targetDetails?.name + " / BartUI"
      : document.title;
  }, [state.targetDetails?.name]);

  if (!token) return null;
  return (
    <div className="messages_table">
      <Flex justify="space-between" style={{ marginBottom: "20px" }}>
        <InfoHeader />
        <Rollback title="back to targets list" delta={"/ui/targets"} />
      </Flex>
      <Tabs
        style={{ color: "var(--table-text-color)" }}
        activeKey={state.tab}
        items={get_tabs(routeParams.id)}
        onChange={(key) => {
          actions.setTab(key);
        }}
      />
    </div>
  );
};

export default TargetInfoPage;
