import {
  CheckSquareFilled,
  CloseSquareFilled,
  SaveOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import cls from "../notes.module.css";

type Props = {
  checkClose: () => void;
  addNewNote: () => void;
  saveAndExit: () => void;
};

const EditFormBtns: React.FC<Props> = ({
  checkClose,
  addNewNote,
  saveAndExit,
}) => {
  return (
    <>
      <Tooltip title="Close" color="red">
        <CloseSquareFilled
          style={{ color: "red" }}
          className={cls.addNewBtn}
          onClick={checkClose}
        />
      </Tooltip>
      <Tooltip title="Save changes" color="green">
        <SaveOutlined className={cls.addNewBtn} onClick={addNewNote} />
      </Tooltip>
      <Tooltip title="Save and exit" color="green">
        <CheckSquareFilled className={cls.addNewBtn} onClick={saveAndExit} />
      </Tooltip>
    </>
  );
};

export default EditFormBtns;
