import { TargetApi } from "../../../../Api";
import { HostInfo, actionsType } from "../../../../shared";
import { handle_data, setStartKeys } from "../actions";
import { IHostState } from "./IHostState";

const TARGET_CLIENT = new TargetApi();
export const actions = {
  setHostInfo:
    (id: string, rerender: boolean) =>
    async ({ setState, getState }: actionsType<IHostState>) => {
      let hostDetails: HostInfo = handle_data(
        await TARGET_CLIENT.getHostDetails(id)
      );

      !rerender && setStartKeys(hostDetails);
      setState({ ...getState(), hostDetails });
    },
  setChangeAlert:
    (change_alert: boolean) =>
    ({ setState, getState }: actionsType<IHostState>) =>
      setState({ ...getState(), change_alert }),

  setKeys:
    (keys: string[]) =>
    ({ setState, getState }: actionsType<IHostState>) =>
      setState({ ...getState(), keys }),

  setLogs:
    (id: string) =>
    async ({ setState, getState }: actionsType<IHostState>) => {
      let logs = await TARGET_CLIENT.getHostLogs(id);
      setState({ ...getState(), logs });
    },

  setRerender:
    (reRender: boolean) =>
    ({ setState, getState }: actionsType<IHostState>) =>
      setState({ ...getState(), reRender }),

  setTab:
    (tab: string) =>
    ({ setState, getState }: actionsType<IHostState>) =>
      setState({ ...getState(), tab }),
};
