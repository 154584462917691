import { useBinaryState } from '../Store';

const HelpModal = () => {
  const [state, actions] = useBinaryState()
  return (
    <div className='help_modal'
      style={{ display: state.isHelpModal ? 'block' : 'none' }}
      onClick={() => actions.setIsHelpModal(false)}>
      <div className="help_info" onClick={e => e.stopPropagation()}>
        <textarea disabled className='help_text' defaultValue={state.help} />
        <button className='close_btn' onClick={() => actions.setIsHelpModal(false)}>Close</button>
      </div>
    </div>
  )
}

export default HelpModal;