import {
  Tabledata,
  actionsType,
  collection_result_analysis,
} from "../../../shared";
import { ITableState } from "./ITableState";

export const actions = {
  setTableData:
    (tabledata: Tabledata[]) =>
    ({ setState, getState }: actionsType<ITableState>) =>
      setState({ ...getState(), tabledata }),

  setLoading:
    (isLoading: boolean) =>
    ({ setState, getState }: actionsType<ITableState>) =>
      setState({ ...getState(), isLoading }),

  setRemoved:
    (isRemoved: boolean) =>
    ({ setState, getState }: actionsType<ITableState>) =>
      setState({ ...getState(), isRemoved }),

  setSelectedRowKeys:
    (selectedRowKeys: never[]) =>
    ({ setState, getState }: actionsType<ITableState>) =>
      setState({ ...getState(), selectedRowKeys }),

  setCollectionsAnalysis:
    (collection_analysis: collection_result_analysis[]) =>
    ({ setState, getState }: actionsType<ITableState>) =>
      setState({ ...getState(), collection_analysis }),
};
