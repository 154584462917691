import TextArea from "antd/es/input/TextArea";
import { CMDStore } from "../store";
import { ExpandBtns } from "./ExpandBtns";

export const defaultExpandable = () => {
    const state = CMDStore.storeState.getState()
    return {
        expandedRowKeys: state.expandedKeys,
        expandedRowRender: (record: any) =>
            <TextArea className='log_text' style={{
                fontFamily: "'Courier New', Courier, monospace"
            }} readOnly autoSize
                value={record.result}
            />,
        rowExpandable: (record: any) => {
            return record.result && record.result.length > 0
        },
        onExpand: (expanded: any, record: any) => {
            CMDStore.actions.setExpandKeys(
                expanded ? [...state.expandedKeys, record.id]
                    : state.expandedKeys.filter(i => i !== record.id))
        },
        columnTitle: <ExpandBtns />
    }
};

export const hideExpand = () => { CMDStore.actions.setExpandKeys([]); }

export const expandAll = () => {
    const state = CMDStore.storeState.getState()
    CMDStore.actions.setExpandKeys(
        state.hostCommands?.filter(i => i.result.length > 0)
            .map(i => i.id)
    )
}



