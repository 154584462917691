import cls from '../styles.module.css'
import { ThemeIcon } from '.'

export const Theme = () => {
    return (
        <div className={cls.theme_icons}>
            {/* <img className="christmas" src={christmas} alt="" onClick={change_theme}></img> */}
            {ThemeIcon()}
        </div>
    )
}

