export const setCookie = (name: string, value: string, days?: number) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export const getCookie = (name: string) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export const deleteCookie = (name: string) => {
  let expires = null
  const d = new Date();
  d.setTime(d.getTime() - 2 * 24 * 60 * 60 * 1000);
  expires = "expires=" + d.toUTCString();
  document.cookie = name + "=" + name + ";" + expires + ";path=/";
}