import { ColumnsType } from "antd/es/table";
import { Status, StatusIcon } from "../../../shared";
import { renderCopyButton, renderDate, renderStopButton } from "../actions";

export const setColumns = (
    handleStopSession: (value: any) => void,
    type: 'revshell' | 'proxy',
    isDisabled?: boolean
): ColumnsType<any> => {

    return [
        {
            title: "Status",
            dataIndex: "status",
            key: "Status",
            width: 20,
            render: (Status: Status) => StatusIcon(Status),
        },
        {
            title: "Last seen",
            dataIndex: "lastSeen",
            key: "LastSeen",
            width: 150,
            render: (lastSeen: string) => renderDate(lastSeen),
        },
        {
            title: "Stop",
            dataIndex: "targetHostId",
            key: "Stop",
            width: 35,
            render: (targetHostId: string) => renderStopButton(targetHostId, handleStopSession, type, !!isDisabled),
        },
        {
            title: "Copy connection String",
            dataIndex: "connectionString",
            key: "ConnectionString",
            width: 35,
            render: (connectionString: string) => renderCopyButton(connectionString, !!isDisabled)
        }
    ];
}