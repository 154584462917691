import { FormInstance } from "antd";
import { TableDataApi, TargetApi } from "../../../Api";
import { BinaryStore } from "../Store";
import {
  DEFAULT_ID,
  getCache,
  SearchTargetsModel,
  setCache,
} from "../../../shared";

const DATA_SERVICE = new TableDataApi();
const TARGET_CLIENT = new TargetApi();

export const showbbParts = async (form: FormInstance<any>, value: any) => {
  BinaryStore.actions.setChekedKind([value]);
  const state = BinaryStore.storeState.getState();
  if (!state.isBb && state.bbOptions.length === 0) {
    let types = await getCache("bbOptions");
    let options: string[] = [];
    options = types?.length === 0 ? await DATA_SERVICE.getBBOptions() : types;
    types?.length === 0 && setCache(options, "bbOptions");

    BinaryStore.actions.setBBOptions(options);
  }
  if (value === "bb") {
    form.setFieldValue("logging", "console");
  } else if (value !== "bb-custom") {
    form.setFieldValue("parts", []);
    BinaryStore.actions.setIsBB(false);
    form.setFieldValue("logging", "none");
  } else {
    BinaryStore.actions.setIsBB(true);
  }
};

export const showHelpModal = async (value: any) => {
  const state = BinaryStore.storeState.getState();
  if (!state.isLoading) {
    BinaryStore.actions.setHelp(
      value.includes("dll")
        ? "There is no information for the specified type"
        : await DATA_SERVICE.getBinaryHelp(value)
    );
    BinaryStore.actions.setIsHelpModal(true);
  }
};

export const submit = async (
  values: any,
  targetsList: SearchTargetsModel[],
  clear: () => void
) => {
  const state = BinaryStore.storeState.getState();
  if (!state.isLoading) {
    BinaryStore.actions.setIsLoading(!state.isLoading);
    await DATA_SERVICE.buildBinaryFile({
      ...values,
      tags:
        values.tags.length > 0 ? values.tags.replace(/ /g, "").split(",") : [],
      target_id:
        values.target_id === "none"
          ? DEFAULT_ID
          : targetsList?.find((i) => i.name === values.target_id)?.id,
    });
    BinaryStore.actions.setIsFormOpen(!state.isFormOpen);
    clear();
  }
};

export const getTargetInfo = async (
  targetName: string,
  form: FormInstance<any>,
  targetsList: SearchTargetsModel[]
) => {
  if (targetName !== "none") {
    let id = targetsList.find((i) => i.name === targetName)?.id;
    let target = await TARGET_CLIENT.getTargetData(`${id}`);
    target && form.setFieldValue("server_ip", target.target.server_ip);
    target && form.setFieldValue("domain", target.target.domain);
  } else {
    form.setFieldValue("server_ip", "");
    form.setFieldValue("domain", "");
  }
};
