export const FAVORITE_KEYS: { [key: string]: string } = {
  favoriteTargets: "favoriteTargets",
};

export const ADVANCED_BB_FIELDS = [
  "beacon",
  "beacon-dll",
  "revshell",
  "revshell-dll",
];

export const pagination_options = {
  defaultPageSize: 50,
  showSizeChanger: true,
  pageSizeOptions: ["50", "100", "150", "200"],
};

export const themes = ["light", "dark", "earth"];

export const COMMANDS_METHODS = ["revshell", "gsnetcat", "gecko", "teardrop"];
