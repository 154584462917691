import { RollbackOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { To, useNavigate } from "react-router-dom";

type Props = {
  title: string;
  customFn?: () => void;
  delta: To | number;
};

export const Rollback = ({ title, customFn, delta }: Props) => {
  const navi = useNavigate();
  return (
    <Tooltip placement="topLeft" title={title} color="#cd9423">
      <RollbackOutlined
        className="title_btns rollback_btn"
        onClick={() => {
          navi(-1);
        }}
      />
    </Tooltip>
  );
};
