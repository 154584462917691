import { Tooltip } from "antd";
import { useTargetState } from "../Store";

export const InfoHeader = () => {
  const [state] = useTargetState();
  return (
    <div style={{ width: "90%" }}>
      <h2 style={{ width: "100%", display: "flex", flexDirection: "row" }}>
        <span
          style={{
            marginRight: "100px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <b style={{ display: "block", width: "80px" }}>Target:</b>
          {state.targetDetails?.name}
        </span>
        <Tooltip placement="topLeft" title={state.targetDetails?.storage_url}>
          <a
            style={{
              alignSelf: "center",
              fontSize: "16px",
              textDecoration: "underline",
            }}
            href={state.targetDetails?.storage_url}
            target="_blank"
            rel="noreferrer"
          >
            Files
          </a>
        </Tooltip>
      </h2>
      <div className="left_block">
        <p style={{ display: "flex", flexDirection: "row" }}>
          <b style={{ display: "block", width: "80px" }}>Domain:</b>
          {state.targetDetails?.domain}
        </p>
        <p style={{ display: "flex", flexDirection: "row" }}>
          <b style={{ display: "block", width: "80px" }}>Server IP:</b>
          <>{state.targetDetails?.server_ip}</>
        </p>
      </div>
    </div>
  );
};
