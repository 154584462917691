import { Tooltip } from "antd";
import { FAVORITE_KEYS, setCookie } from "../../../shared";
import { NavigateFunction } from "react-router-dom";
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import { TargetStore } from "../Store";
import { editTargetList, removeTarget } from "./actions";

export const renderActions = (record: any, navi: NavigateFunction) => {
  return (
    <div className="action-btns">
      <InfoCircleOutlined
        title="Info"
        style={{ color: "rgb(74, 74, 245)" }}
        onClick={() => {
          navi(`/ui/target/${record.id}/info`);
        }}
      />
      <span
        title="Mark as favourite"
        style={{ color: "#F4A460" }}
        onClick={(e) => {
          mark_favorite(record.id);
        }}
      >
        {record.isFavorite ? <StarFilled /> : <StarOutlined />}
      </span>
      <EditOutlined
        title="Rename target"
        onClick={() => {
          editTargetList(record);
        }}
        style={{ color: "green" }}
      />
      <DeleteOutlined
        title="Delete target"
        style={{ color: "red" }}
        onClick={(e) => {
          e.preventDefault();
          removeTarget(record);
        }}
      />
    </div>
  );
};

export const renderFileLink = (storage_url: string) => (
  <Tooltip placement="topLeft" title={storage_url}>
    <a href={storage_url} rel="noreferrer" target="_blank">
      Files
    </a>
  </Tooltip>
);

export const mark_favorite = (id: string) => {
  let favorites = TargetStore.storeState.getState().favorites;
  let fav = favorites.includes(id)
    ? favorites.replace(`${id},`, "")
    : favorites + `${id},`;
  setCookie(FAVORITE_KEYS.favoriteTargets, fav, 365);
  TargetStore.actions.setFavorites(fav);
};

export const onContextMenu = (
  event: React.MouseEvent<any, MouseEvent>,
  record: any
) => {
  if ((event.target as HTMLElement).tagName.toLowerCase() !== "a") {
    event?.preventDefault();
    if (!TargetStore.storeState.getState().isContext) {
      document.addEventListener(`click`, function onClickOutside() {
        TargetStore.actions.setIsContext(false);
        TargetStore.actions.setRecordContext(undefined);
        document.removeEventListener(`click`, onClickOutside);
      });
    }
    TargetStore.actions.setIsContext(true);
    TargetStore.actions.setRecordContext(record);
    TargetStore.actions.setContextPosition({
      x: event.pageX,
      y: event.pageY,
    });
  }
};
