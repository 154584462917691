import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AppstoreAddOutlined } from "@ant-design/icons";
import { Table } from "antd";

import { getCookie } from "../../shared";
import { useTargetState } from "./Store";
import cls from "./targets.module.css";
import { TargetColumns } from "./ui";
import { editTargetList, onContextMenu } from "./actions";
import ContextMenu from "../shared/ContextMenu/ContextMenu";
import { getTargetContextItems } from "./ui/contextMenuItems";

const TargetsPage = () => {
  const token = getCookie("token") as string;
  const navi = useNavigate();
  const [state, actions] = useTargetState();

  useEffect(() => {
    actions.setTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.favorites, state.isRerender]);

  useEffect(() => {
    document.title = "Targets / BartUI";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !token ? (
    <></>
  ) : (
    <div className="messages_table">
      <AppstoreAddOutlined
        title="Add new target"
        className={cls.addNewGroupBtn}
        onClick={editTargetList}
      />
      <Table
        className={cls.targetsTable}
        columns={TargetColumns()}
        dataSource={state.tabledata}
        pagination={false}
        rowKey="id"
        rowClassName={(record) => (record.isFavorite ? "favoriteRow" : "")}
        onChange={(_, filter, sorter, extra) => {
          if (filter) {
            actions.setFilters(filter);
          }
        }}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              navi(`/ui/target/${record.id}/info`);
            },
            onContextMenu: (event) => onContextMenu(event, record),
          };
        }}
      />
      {state.isContext && (
        <ContextMenu
          visible={state.isContext}
          position={state.contextPosition}
          items={getTargetContextItems(state.record)!}
        />
      )}
    </div>
  );
};

export default TargetsPage;
