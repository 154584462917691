import { useContext } from "react";
import { TargetContext, Toggle, getTargetSelects } from "../../../shared";
import { useHostState } from "../store";
import { Select } from "antd";
import { HeaderBtns, InfoSection } from ".";
import { update_host } from "../actions";
import { host_alert_level } from "../../ui/hostAlert";
import ChangeAlertModal from "./ChangeAlertModal";

export const InfoHeader = ({ id }: { id: string }) => {
  const [state] = useHostState();
  const { targetsList } = useContext(TargetContext)!;

  return (
    <>
      <div className="header_section">
        <div className="target_name">
          <h3>HOSTNAME: </h3>
          <p>
            {" "}
            {state.hostDetails.target_host?.id === id &&
              state.hostDetails?.target_host?.hostname}
          </p>
        </div>
        <div className="select_section">
          <label className="target_label">Target: </label>
          <Select
            className="info_groups_select"
            showSearch={true}
            value={
              state.hostDetails.target_host?.id === id &&
              state.hostDetails.target_host?.target
                ? state.hostDetails.target_host?.target
                : "none"
            }
            options={getTargetSelects(targetsList)}
            onChange={async (value) => update_host(value, targetsList, id)}
          />
        </div>
        <div className="silent_toggle">
          <Toggle
            active={state.hostDetails.target_host?.silent!}
            hideReload={true}
            setActive={() => {
              update_host(
                state.hostDetails.target_host?.target
                  ? state.hostDetails.target_host?.target
                  : "none",
                targetsList,
                id,
                !state.hostDetails.target_host?.silent
              );
            }}
            title="Silent:"
          ></Toggle>
        </div>
        <div className="alert_level">
          {host_alert_level(state.hostDetails.target_host?.alert_level, id)}
        </div>
        <HeaderBtns />
      </div>
      <InfoSection />
      <ChangeAlertModal id={id} />
    </>
  );
};
