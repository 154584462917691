import { TableRowSelection } from "antd/es/table/interface";
import { IBeaconState } from "../../Beacon/Store";
import { ICredsState } from "../../CredentialsPage/Store";
import { ITableState } from "../../Table/store";
import { ITargetHostState } from "../../TargetHosts/Store";

type state = IBeaconState | ICredsState | ITableState | ITargetHostState;

export const get_rowSelection = (state: state, actions: any) => {
  return {
    type: "checkbox",
    selectedRowKeys: state.selectedRowKeys,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      actions.setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record: any) => ({
      id: record.id,
    }),
    columnWidth: 35,
  } as TableRowSelection<any>;
};
