import React from "react";
import { useEffect } from "react";
import { CSVLink } from "react-csv";

import { Button, ConfigProvider, Form, Input, Select, Tooltip } from "antd";
import {
  CloudDownloadOutlined,
  DownloadOutlined,
  EnterOutlined,
  FilterFilled,
  FilterOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
  SnippetsFilled,
} from "@ant-design/icons";

import { Targets, DEFAULT_ID } from "../../../shared";
import AuthLocalStorage from "../../../AuthLocalStorage";
import cls from "./SearchTargets.module.css";
import { getHeaderTargetSelects, get_search_types } from ".";
import { TargetContext } from "..";
import DeleteMultiBtn from "../DeleteMultiBtn/DeleteMultiBtn";

type Props = {
  getData: (data?: any) => Promise<void> | any;
  type: string;
  tableData?: any[];
  typesList: string[];
  setMulti?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | ((isMulti: boolean) => void);
  downloadSelectedKeys?: () => void;
};

type TargetSelects = {
  get: Function;
  set: Function;
  remove: Function;
};

export const SearchTargets: React.FC<Props> = ({
  getData,
  type,
  tableData,
  typesList,
  setMulti,
  downloadSelectedKeys,
}) => {
  const [form] = Form.useForm();
  const [isDirty, setIsDirty] = React.useState(false);
  const { targetsList } = React.useContext(TargetContext)!;

  const targetSelects: TargetSelects = ((type) => {
    switch (type) {
      case "net":
        return {
          get: AuthLocalStorage.getTargetSelects,
          set: AuthLocalStorage.setTargetSelects,
          remove: AuthLocalStorage.removeTargetSelects,
        };
      case "target_net":
        return {
          get: AuthLocalStorage.getTargetNetSelects,
          set: AuthLocalStorage.setTargetNetSelects,
          remove: AuthLocalStorage.removeTargetNetSelects,
        };
      case "beacon":
        return {
          get: AuthLocalStorage.getBeaconSelects,
          set: AuthLocalStorage.setBeaconSelects,
          remove: AuthLocalStorage.removeBeaconSelects,
        };
      case "target_beacon":
        return {
          get: AuthLocalStorage.getTargetBeaconSelects,
          set: AuthLocalStorage.setTargetBeaconSelects,
          remove: AuthLocalStorage.removeTargetBeaconSelects,
        };
      case "creds":
        return {
          get: AuthLocalStorage.getCredsSelects,
          set: AuthLocalStorage.setCredsSelects,
          remove: AuthLocalStorage.removeCredsSelects,
        };

      case "collections":
        return {
          get: AuthLocalStorage.getCollectionsSelects,
          set: AuthLocalStorage.setCollectionsSelects,
          remove: AuthLocalStorage.removeCollectionsSelects,
        };
      default:
        return {
          get: AuthLocalStorage.getHostSelects,
          set: AuthLocalStorage.setHostSelects,
          remove: AuthLocalStorage.removeHostSelects,
        };
    }
  })(type);
  const targetsFromStorage: string | null = targetSelects.get();
  const initialValues: Targets = new Targets();
  const savedValues: Targets = !!targetsFromStorage
    ? JSON.parse(targetsFromStorage!)
    : undefined;

  const isTargetsInitial = (): boolean => {
    const values = form.getFieldsValue();
    let isInitial = true;

    Object.keys(values).forEach((key) => {
      if (values[key] !== initialValues[key as keyof Targets]) {
        return (isInitial = false);
      }
    });

    return isInitial;
  };

  const flatNestedObj = (data: any) => {
    data = data?.map((i: any) => {
      return { ...(({ target_host, ...o }) => o)(i), ...i.target_host };
    });
    return data;
  };

  const getInitValues = (): Targets => {
    return savedValues || { ...initialValues };
  };

  const setSelects = (): void => {
    setIsDirty(!isTargetsInitial());
    let target: Targets = form.getFieldsValue();
    target.target_id =
      target.name === "none"
        ? DEFAULT_ID
        : targetsList?.find((i) => i.name === target.name)?.id;
    targetSelects.set(target);
    getData();
  };

  const clearSelect = (): void => {
    setIsDirty(false);
    form.setFieldsValue(new Targets());
    targetSelects.remove();
    getData();
  };

  const exportConnStrings = (records: any[]) => {
    var element = document.createElement("a");
    var text = records
      .map((item) => {
        return `${item.connection_string} # ${item.client_ip} ${item.comment}`;
      })
      .join("\n");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", "conns.txt");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  useEffect(() => {
    setIsDirty(!isTargetsInitial());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  return (
    <div className={cls.targets_search}>
      <ConfigProvider
        theme={{
          components: {
            Select: {
              optionFontSize: 16,
            },
            Form: {
              labelHeight: 40,
            },
          },
        }}
      >
        <Form
          form={form}
          initialValues={getInitValues()}
          className={cls.targetsForm}
        >
          {isDirty ? (
            <FilterFilled className={cls.targetsIconActive} />
          ) : (
            <FilterOutlined className={cls.targetsIcon} />
          )}
          {(type === "net" || type === "beacon" || type === "creds") && (
            <Form.Item
              name="type"
              className={cls.targetSelect}
              label={type === "creds" ? "Source" : "Type:"}
            >
              <Select
                size="large"
                options={get_search_types(typesList)}
                placeholder={`select ${type === "creds" ? "Source" : "type"}`}
                onChange={setSelects}
              />
            </Form.Item>
          )}
          {!["target_net", "target_beacon"].includes(type) && (
            <Form.Item
              name="name"
              className={cls.targetSelect}
              label={"Target"}
            >
              <Select
                size="large"
                showSearch={true}
                options={getHeaderTargetSelects(targetsList)}
                onChange={setSelects}
                placeholder="select target"
              />
            </Form.Item>
          )}
          <Form.Item
            name="search_phrase"
            className={cls.searchInput}
            label="Search:"
          >
            <Input
              size="large"
              prefix={<SearchOutlined className="site-form-item-icon" />}
              suffix={
                <EnterOutlined
                  style={{ position: "absolute", right: "10px" }}
                  onClick={setSelects}
                />
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setSelects();
                }
              }}
            />
          </Form.Item>
          <Button className={cls.cancelBtn} onClick={clearSelect}>
            Clear
          </Button>
          <div className={cls.targetActions}>
            {type === "creds" && downloadSelectedKeys ? (
              <Tooltip color="rgb(222 169 230)" title="Download selected keys">
                <SnippetsFilled
                  className={cls.downloadCsvNet}
                  style={{ color: "rgb(222 169 230)", cursor: "pointer" }}
                  onClick={downloadSelectedKeys}
                />
              </Tooltip>
            ) : (
              <></>
            )}
            {tableData &&
            !["target_beacon", "target", "collections"].includes(type) ? (
              <Tooltip title="Download CSV">
                <CSVLink
                  className={cls.downloadCsvNet}
                  data={
                    type !== "beacon" ? tableData : flatNestedObj(tableData)
                  }
                >
                  <DownloadOutlined />
                </CSVLink>
              </Tooltip>
            ) : (
              <></>
            )}
            {tableData && type === "net" ? (
              <Tooltip title="Export conn strings">
                <CloudDownloadOutlined
                  className={cls.downloadCsvNet}
                  onClick={() => exportConnStrings(tableData ? tableData : [])}
                />
              </Tooltip>
            ) : (
              <></>
            )}
            {setMulti && (
              <Tooltip
                placement="topLeft"
                title={type === "beacon" ? "Add messages" : "Add commands"}
              >
                <MenuUnfoldOutlined
                  className={cls.showMulti}
                  onClick={() => {
                    setMulti!(true);
                  }}
                />
              </Tooltip>
            )}
            {setMulti && type === "target" && (
              <DeleteMultiBtn getData={getData} />
            )}
            {tableData && (
              <span className={cls.recordsCount}>
                <span style={{ color: "red" }}>{tableData?.length}</span>{" "}
                records
              </span>
            )}
          </div>
        </Form>
      </ConfigProvider>
    </div>
  );
};
