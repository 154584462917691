import { Dropdown, MenuProps } from "antd";

type Props = {
  items: MenuProps["items"];
  visible: boolean | undefined;
  position: { x: number; y: number } | undefined;
};

const ContextMenu = ({ items, visible, position }: Props) => {
  return (
    <Dropdown
      menu={{ items }}
      open={visible}
      overlayStyle={{ left: `${position?.x}px`, top: `${position?.y}px` }}
    >
      <></>
    </Dropdown>
  );
};

export default ContextMenu;
