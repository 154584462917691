import React from "react";
import { ClipLoader } from "react-spinners";

import { Drawer, Form } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { SearchTargetsModel } from "../../../../shared";

import { override } from "./CSSProps";

import HelpModal from "../HelpModal";
import { useBinaryState } from "../../Store";
import BBForm from "./BBForm";

type Props = {
  targetsList: SearchTargetsModel[];
};

export const BBDrawer: React.FC<Props> = ({ targetsList }) => {
  const [form] = Form.useForm();
  const [state, actions] = useBinaryState();

  const clear = () => {
    form.resetFields();
    actions.clearStates();
  };

  return (
    <div className="fileForm">
      <div className="sweet-loading">
        <ClipLoader
          color="red"
          loading={state.isLoading}
          cssOverride={override}
          size={190}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
      <Drawer
        style={{ backgroundColor: "var(--primary)", position: "relative" }}
        title="Build binary"
        placement={"left"}
        closable={true}
        onClose={clear}
        open={state.isFormOpen}
        key={"left"}
        closeIcon={<CloseOutlined onClick={clear} />}
      >
        <div>
          <BBForm targetsList={targetsList} form={form} clear={clear} />
        </div>
      </Drawer>
      <HelpModal />
    </div>
  );
};
