import { createHook, createStore, defaultRegistry } from "react-sweet-state";
import { ITargetHostState } from "./ITargetHostState";
import { actions } from "./Actions";

const initialState: ITargetHostState = {
  isActive: false,
  isLoading: false,
  isMulti: false,
  tabledata: [],
  typesList: [],
  selectedColumns: [],
  selectedRowKeys: [],
  filters: undefined,
  alert_id: undefined,
};

export const Store = createStore<ITargetHostState, typeof actions>({
  initialState,
  actions,
});

export const useTargetHostState = createHook(Store);

export const HostStore = defaultRegistry.getStore(Store);
