import { SettingOutlined } from "@ant-design/icons";
import cls from './TableSettings.module.css';

type Props = {
    children: React.ReactNode;
};

export const TableSettings: React.FC<Props> = ({ children }) => {
    return (
        <details className={cls.table_settings}>
            <summary className={cls.table_settings__header}><SettingOutlined /> Table settings</summary>
            <section className={cls.table_settings__body}>
                <div className="topSection">
                    <div className="selectors">{ children }</div>
                </div>
            </section>    
        </details>
    );
};
