import { createHook, createStore, defaultRegistry } from "react-sweet-state";
import { IAdminState } from "./IAdminState";
import { actions } from "./Actions";

const initialState: IAdminState = {
  clients: [],
  users: [],
  isClientEdit: false,
  isUserEdit: false,
  editedClient: null,
  editedUser: undefined,
  showTokens: false,
  serverToken: [],
  current_user: {
    allowed_roles: [""],
    client_id: "",
    roles: [],
    id: "",
    login: "",
    allowed_binaries: [""],
  },
};

export const Store = createStore<IAdminState, typeof actions>({
  initialState,
  actions,
});

export const useAdminState = createHook(Store);

export const adminStore = defaultRegistry.getStore(Store);
