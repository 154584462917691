import React from "react";
import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import cls from "./Toggle.module.css";
import Tooltip from "antd/es/tooltip";

type Props = {
  active: boolean;
  loading?: boolean;
  setActive:
    | React.Dispatch<React.SetStateAction<boolean>>
    | ((isActive: boolean) => void);
  onClick?: () => void;
  title?: string;
  disable?: boolean;
  hideReload?: boolean;
};

export const Toggle = (props: Props) => {
  return (
    <div
      className={cls.flexToggle}
      style={{
        width: props.hideReload ? "195px" : "300px",
        justifyContent: props.hideReload ? "space-between" : "",
        marginBottom: props.hideReload ? "10px" : "0",
      }}
    >
      <p style={{ width: "150px" }}>
        {props.title ? props.title : "Autorefresh:"}
      </p>
      {props.disable ? (
        <Tooltip
          title="Gateways can't be rendered without routes"
          trigger="click"
          color="red"
        >
          <div
            className={`toggle ${props.active ? "active" : ""}`}
            onClick={() => !props.disable && props.setActive(!props.active)}
          >
            <div />
          </div>
        </Tooltip>
      ) : (
        <div
          className={`toggle ${props.active ? "active" : ""}`}
          onClick={() => !props.disable && props.setActive(!props.active)}
        >
          <div />
        </div>
      )}
      {props.hideReload ? (
        <></>
      ) : props.loading ? (
        <LoadingOutlined className="reload-btn" />
      ) : (
        <ReloadOutlined
          className="reload-btn"
          onClick={props.onClick}
          title="reload"
        />
      )}
    </div>
  );
};
